import React from "react";
import PropTypes from "prop-types";

const Error500 = ({ error }) => {
  console.error(error);

  return (
    <div className="container">
      <div className="row">
        <div className="col-16">
          <h1 className="text-center">500</h1>
          <p className="text-center">Ein Problem ist aufgetreten.</p>
          <pre className="text-center">{error.message}</pre>
        </div>
      </div>
    </div>
  );
};

Error500.propTypes = {
  error: PropTypes.object.isRequired,
};

export default Error500;
