import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * Use this component for rendering images queried from Drupal.
 */
class Image extends Component {
  constructor() {
    super();
    this.state = {
      highResLoaded: false,
    };
  }

  render() {
    if (!this.props.data) {
      return;
    }

    let { alt, title } = this.props.data;

    if (!alt && this.props.caption) {
      alt = this.props.caption;
    } else if (!alt && this.props.nodeTitle) {
      alt = this.props.nodeTitle;
    }

    if (!title && this.props.caption) {
      title = this.props.caption;
    } else if (!title && this.props.nodeTitle) {
      title = this.props.nodeTitle;
    }

    if (this.props.fullPage) {
      return (
        <>
          {this.props.credit && (
            <span className="credit">© {this.props.credit}</span>
          )}
          <img
            className={this.props.className}
            src={this.props.data.styleFullPage.url}
            title={title}
            alt={alt || ""}
          />
        </>
      );
    }

    if (this.props.data.mobile && this.props.data.desktop) {
      return [
        <img
          key="mobile-image"
          className={`${this.props.className} mobile-image d-block d-md-none`}
          src={this.props.data.mobile.url}
          title={title}
          alt={alt || ""}
        />,
        <img
          key="desktop-image"
          className={`${this.props.className} desktop-image d-none d-md-block`}
          src={this.props.data.desktop.url}
          title={title}
          alt={alt}
        />,
      ];
    }

    return (
      <>
        {this.props.lqip && !this.state.highResLoaded ? (
          <img
            className={`${this.props.className} lqip-image`}
            src={this.props.data.loading.url}
            title={title}
            width={this.props.data.style.width}
            height={this.props.data.style.height}
            alt={alt || ""}
          />
        ) : null}
        <img
          className={classNames({
            [this.props.className]: true,
            "lqip-enabled": this.props.lqip,
            "high-res-loaded": this.state.highResLoaded,
          })}
          width={this.props.data.style.width || undefined}
          height={this.props.data.style.height || undefined}
          src={this.props.data.style.url}
          title={title}
          alt={alt || ""}
          onLoad={() => this.setState({ highResLoaded: true })}
        />
        {(this.props.caption ||
          this.props.credit ||
          this.props.globalCaption) && (
          <div
            className={classNames({
              "caption-wrap": true,
              "col-16": this.props.wrapCaption,
            })}
          >
            {(this.props.caption || this.props.globalCaption) && (
              <span className="caption small">
                {this.props.caption || this.props.globalCaption}
              </span>
            )}
            {this.props.credit && (
              <span className="credit small">© {this.props.credit}</span>
            )}
          </div>
        )}
      </>
    );
  }
}

Image.propTypes = {
  /**
   * The image caption.
   */
  caption: PropTypes.string,
  /**
   * Used as fallback alt="" value.
   */
  nodeTitle: PropTypes.string,
  /**
   * The image credit.
   */
  credit: PropTypes.string,
  data: PropTypes.shape({
    alt: PropTypes.string,
    title: PropTypes.string,
    style: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    styleFullPage: PropTypes.shape({
      url: PropTypes.string,
    }),
    desktop: PropTypes.shape({
      url: PropTypes.string,
    }),
    mobile: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  /**
   * Custom class names to add to the component.
   */
  className: PropTypes.string,
  /**
   * Only provided if used inside an image gallery component.
   */
  globalCaption: PropTypes.string,
  /**
   * In fullpage-mode, image and caption are swapped.
   */
  fullPage: PropTypes.bool,
  /**
   * Wrap caption?
   */
  wrapCaption: PropTypes.bool,
};

export default Image;
