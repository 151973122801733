import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Image from "../../../image/image";

const ParagraphImageCarousel = ({ content }) => {
  const sliderRef = useRef();
  const [currentSlide, setCurrentSlide] = useState(0);

  const sliderSettings = {
    adaptiveHeight: true,
    initialSlide: currentSlide,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: true,
    dots: false,
    variableWidth: true,
    infinite: false,
    centerMode: true,
    afterChange: (_currentSlide) => {
      setCurrentSlide(_currentSlide);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          variableWidth: false,
        },
      },
    ],
  };

  const images = content.fieldBilderWCaptionRawField.list.map((item) => ({
    customCaption: item.customCaption,
    defaultCaption: item.defaultCaption,
    ...item.entity,
  }));

  return (
    <section className="paragraph paragraph-image-carousel">
      <Slider {...sliderSettings} ref={sliderRef}>
        {images.map((item, index) => (
          <div key={index} className="image-carousel-item">
            <Image data={item.fieldMediaImage} caption={item.fieldCaption} />
          </div>
        ))}
      </Slider>
      <div className="container carousel-meta">
        <div className="row">
          <div className="col-5 col-sm-3">
            <div className="chip-wrapper">
              <span className="chip">
                {(currentSlide + 1).toString().padStart(2, "0")} /{" "}
                {images?.length.toString().padStart(2, "0")}
              </span>
            </div>
          </div>
          <div className="col-7 col-sm-9 col-md-6 col-xl-5 offset-xl-1">
            <p className="small">
              {(images[currentSlide].defaultCaption
                ? images[currentSlide].fieldCaption
                : images[currentSlide].customCaption) || content.fieldBildunterschrift}{" "}
              {images[currentSlide].fieldCredit && (
                <span className="credit">
                  &copy;
                  {images[currentSlide].fieldCredit}
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphImageCarousel.propTypes = {};

export default ParagraphImageCarousel;
