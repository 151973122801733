import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { graphql } from "@apollo/client/react/hoc";
import moment from "moment";
import { connect } from "react-redux";

import teaserNodeQueryEventDateFiltered from "../../../teaser-base/queries/teaser-node-query-event-date-filtered.graphql";
import TeaserEvent, {
  teaserEventPropTypes,
} from "../../../teaser-base/event/teaser-event";
import ErrorBoundary from "../../../../error-boundary";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

const ParagraphKalenderAbschnitt = ({ content, nodes }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-kalender-abschnitt": true,
    "heading-only": content.fieldNurUeberschriftZeigen,
  });

  return (
    <section className={sectionClassNames}>
      {content.fieldHeading && (
        <div className="row">
          <div className="col-16">
            <h2>{content.fieldHeading}</h2>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row">
          {!content.fieldNurUeberschriftZeigen &&
            nodes.entityQuery &&
            nodes.entityQuery.items &&
            nodes.entityQuery.items.length > 0 && (
              <>
                {nodes.entityQuery.items.map((item, index) => (
                  <ErrorBoundary key={index}>
                    <TeaserEvent item={item} />
                  </ErrorBoundary>
                ))}
              </>
            )}
        </div>
      </div>
    </section>
  );
};

ParagraphKalenderAbschnitt.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldNurUeberschriftZeigen: PropTypes.bool,
    fieldZeitraumAuswaehlenNach: PropTypes.oneOf(["relative", "static"]),
    fieldFestesDatum: PropTypes.shape({
      value: PropTypes.string,
      endValue: PropTypes.string,
    }),
    fieldRelativesDatum: PropTypes.oneOf([
      "today",
      "tomorrow",
      "this week",
      "next week",
      "this month",
      "next month",
    ]),
  }),
  nodes: PropTypes.shape({
    entityQuery: PropTypes.shape({
      total: PropTypes.number,
      items: PropTypes.arrayOf(teaserEventPropTypes),
    }),
  }),
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryEventDateFiltered, {
    name: "nodes",
    skip: (props) => props.content.fieldNurUeberschriftZeigen,
    options: (props) => {
      let relativeDate = moment().format("YYYY-MM-DD"),
        relativeEndDate = moment().format("YYYY-MM-DD");

      if (props.content.fieldZeitraumAuswaehlenNach === "relative") {
        switch (props.content.fieldRelativesDatum) {
          case "today":
            relativeDate = moment().subtract(1, "days").format("YYYY-MM-DD");
            relativeEndDate = moment().format("YYYY-MM-DD");
            break;
          case "tomorrow":
            relativeDate = moment().format("YYYY-MM-DD");
            relativeEndDate = moment().add(1, "days").format("YYYY-MM-DD");
            break;
          case "this week":
            relativeDate = moment().startOf("week").format("YYYY-MM-DD");
            relativeEndDate = moment().endOf("week").format("YYYY-MM-DD");
            break;
          case "next week":
            relativeDate = moment()
              .add(1, "weeks")
              .startOf("week")
              .format("YYYY-MM-DD");
            relativeEndDate = moment()
              .add(1, "weeks")
              .endOf("week")
              .format("YYYY-MM-DD");
            break;
          case "this month":
            relativeDate = moment().startOf("month").format("YYYY-MM-DD");
            relativeEndDate = moment().endOf("month").format("YYYY-MM-DD");
            break;
          case "next month":
            relativeDate = moment()
              .add(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD");
            relativeEndDate = moment()
              .add(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD");
            break;
        }
      }

      return {
        variables: {
          type: "veranstaltung",
          end_date:
            props.content.fieldZeitraumAuswaehlenNach === "static"
              ? props.content.fieldFestesDatum.endValue
              : relativeEndDate,
          date:
            props.content.fieldZeitraumAuswaehlenNach === "static"
              ? props.content.fieldFestesDatum.value
              : relativeDate,
          language: props.currentLanguage.toUpperCase(),
        },
      };
    },
  })(ParagraphKalenderAbschnitt)
);
