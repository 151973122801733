import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { InlineNotification } from "carbon-components-react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { authenticationPasswordResetTempAction } from "./redux-authentication";

import ExecutionEnvironment from "exenv";
import { restHostBackend } from "../config";

const mapStateToProps = (reduxStore) => ({ auth: reduxStore.auth });

class ResetPasswordWithTempPasswordForm extends Component {
  state = {
    loading: false,
    error: "",
    success: false,
    redirectToIntern: false,
  };

  handleSubmit = (values, actions) => {
    this.setState({
      loading: true,
      error: "",
    });

    /* todo @David removed {self}. is this importend for security? Why not Backend URL? */
    axios
      .post(
        `${restHostBackend}/user/lost-password-reset?_format=json`,
        {
          name: values.email,
          temp_pass: values.temp_pass,
          new_pass: values.new_pass,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
          withCredentials: false,
        }
      )
      .then(() => {
        this.props.dispatch(authenticationPasswordResetTempAction(true));
        this.setState({ success: true });
        setTimeout(() => {
          this.setState({ redirectToIntern: true });
        }, 3000);
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message,
        });
      })
      .then(() => {
        actions.setSubmitting(false);
      });
  };

  render() {
    if (
      !this.props.auth.userLoginData.logout_token &&
      ExecutionEnvironment.canUseDOM
    ) {
      return <Redirect to="/intern/login" />;
    }

    if (this.state.redirectToIntern) {
      return <Redirect to="/intern/intern-willkommen" />;
    }

    return (
      <div className="container">
        <div className="login-wrapper reset row">
          <div className="col-12 col-md-6 offset-md-3">
            {this.state.success && (
              <InlineNotification
                kind="success"
                title="Erfolgreich!"
                subtitle="Passwort wurde geändert."
              />
            )}
            {!this.state.success && this.state.error !== "" && (
              <InlineNotification
                kind="error"
                title="Authentication Error"
                subtitle={this.state.error}
              />
            )}
            <h2>Passwort zurücksetzen</h2>
            <Formik
              onSubmit={(values, actions) => {
                this.handleSubmit(values, actions);
              }}
              initialValues={{
                email: this.props.auth.userLoginData.current_user
                  ? this.props.auth.userLoginData.current_user.name
                  : "",
                temp_pass: "",
                new_pass: "",
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  //.email()
                  .required(),
              })}
              className="form-container"
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <Field
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Email"
                  />
                  <ErrorMessage name="email" />
                </div>
                <div className="form-group">
                  <label htmlFor="temp_pass">Temporäreres Passwort</label>
                  <Field
                    type="password"
                    name="temp_pass"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage name="temp_pass" />
                </div>
                <div className="form-group">
                  <label htmlFor="new_pass">Neues Passwort</label>
                  <Field
                    type="password"
                    name="new_pass"
                    className="form-control"
                    placeholder="Password"
                  />
                  <ErrorMessage name="new_pass" />
                </div>
                <button type="submit" className="btn btn-primary">
                  Passwort zurücksetzen
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

ResetPasswordWithTempPasswordForm.propTypes = {
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ResetPasswordWithTempPasswordForm);
