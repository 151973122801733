import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import ErrorBoundary from "../../../../error-boundary";

const ParagraphAkkordionText = ({ content, subgrid = true }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-akkordion-text": true,
    "standalone": subgrid,
    "bg-grey": content.fieldColoredBackground
  });

  const paragraph = (
    <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
      {content.fieldAbschnitte.map((item, index) => (
        <ErrorBoundary key={index}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton><span>{item.fieldTitel}</span></AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: item.fieldText,
                }}
              />
            </AccordionItemPanel>
          </AccordionItem>
        </ErrorBoundary>
      ))}
    </Accordion>
  );

  if (subgrid) {
    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-12">{paragraph}</div>
          </div>
        </div>
      </section>
    );
  } else {
    return <section className={sectionClassNames}>
      <div className="row">
        <div className="col-12 col-md-8 col-lg-10 col-xl-8">{paragraph}</div></div></section>;
  }
};

ParagraphAkkordionText.propTypes = {
  subgrid: PropTypes.bool,
  content: PropTypes.shape({
    fieldColoredBackground: PropTypes.bool,
    fieldAbschnitte: PropTypes.arrayOf(
      PropTypes.shape({
        fieldTitel: PropTypes.string,
        fieldText: PropTypes.string,
      })
    ),
  }),
};

export default ParagraphAkkordionText;
