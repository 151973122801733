import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../general-components/loading-indicator";
import ContentBase from "../../general-components/content-base/content-base";
import projectQuery from "./project.graphql";
import PagerFullPage from "../../general-components/pager-full-page";
import { useQuery } from "@apollo/client";
import Error404 from "../../system/error-404";
import Error500 from "../../system/error-500";
import ProjectFactsheet from "./components/project-factsheet";
import ProjectIntro from "./components/project-intro";
import { useI18n } from "../../i18n/use-i18n";
import RelatedProjects from "./components/related-projects";
import { motion } from "framer-motion";

const Project = (props) => {
  /**
   * Hook to get information from Redux Store
   */
  const pagerFullPageContextId = useSelector(reduxStore => reduxStore.appStore.pagerFullPageContextId);
  const pagerConfig = useSelector(reduxStore => reduxStore.appStore.pagerFullPages);
  const microSite = useSelector(reduxStore => reduxStore.appStore.microSite);
  const microSiteFrontPage = useSelector(reduxStore => reduxStore.appStore.microSiteFrontPage);

  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  // @todo: language variations are not EntityCanonicalUrl but RedirectUrl
  const { data, loading, error } = useQuery(projectQuery, {
    variables: {
      path:
        props.location.pathname === "/" &&
        microSite &&
        microSiteFrontPage !== ""
          ? microSiteFrontPage
          : props.location.pathname,
    },
    //onCompleted: (data) => i18nActionHelper({ data, ...props }, dispatch),
  });

  useI18n(data?.route.languageSwitchLinks);

  // Return the loading indicator while loading
  if (loading) {
    return <LoadingIndicator className="page-loading-indicator" />;
  }

  // Return the 500 error component if query was not successful
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but data is empty
  if (!data?.route && !loading) {
    return <Error404 />;
  }

  const published = data.route.entity.status
    ? "node-published"
    : "node-unpublished";

  /**
   * If the first module in fieldModules is a text module, it should be rendered inside the project intro.
   * Therefore the first module is sliced off the modules list and the content is extracted to be rendered
   * inside the ProjectIntro component.
   */
  const modules = {};
  let introText;
  modules.list = data.route.entity.fieldModules.list;
  if (modules?.list[0]?.entity.entityBundle === "text") {
    introText = modules.list[0].entity.fieldText;
    modules.list = modules.list.slice(1);
  }

  return (
    <motion.article
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`node-${data.route.entity.entityBundle} node-${data.route.entity.id} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`Hadi Teherani | ${data.route.entity.label}`}</title>
      </Helmet>

      <ProjectIntro content={data.route.entity} introText={introText} />

      {data.route.entity.fieldModules && <ContentBase content={modules} />}

      <ProjectFactsheet content={data.route.entity} />
      <RelatedProjects content={data.route.entity} />

      {pagerFullPageContextId &&
        props.pagerConfig.filter(
          (config) => config.id === pagerFullPageContextId
        ).length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={data.route.entity.entityId}
          />
        )}
    </motion.article>
  );
};

Project.propTypes = {
  data: PropTypes.object,
  pagerConfig: PropTypes.array,
  location: PropTypes.object.isRequired,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default withRouter(Project);
