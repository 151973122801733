import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base/teaser-base";
import Filters from "./components/filters";
import LoadingIndicator from "../../../loading-indicator";
import { FormattedMessage } from "react-intl";
import OverviewProvider from "./store/overview-provider";
import EndlessScroll from "./components/endless-scroll";
import useOverviewContext from "./store/use-overview-context";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
//import Pagination from "./components/pagination";

const Overview = () => {
  // Get relevant values and functions from the OverviewContext.
  const { nodes, loading, error } = useOverviewContext();
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <section
      className={classNames({
        "paragraph paragraph-extended-teaser-view": true,
      })}
      id="overview"
    >
      {/* Filters */}
      <Filters
        filterOpen={filterOpen}
        closeFilters={() => setFilterOpen(false)}
      />
      <div className="container nodes">
        {!!nodes.length && !error && (
          <>
            {/* Teasers */}
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 575: 1, 767: 2, 1023: 3 }}
            >
              <Masonry gutter="20px">
                {nodes.map((node, i) => (
                  <div key={i}>
                    <TeaserBase item={node} />
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>

            {/* Pagination */}
            {/* <Pagination /> */}
          </>
        )}

        {!nodes.length && !loading && !error && (
          <div className="row">
            <div className="col-16 col-md-8 offset-md-1">
              <FormattedMessage id="overview.no_results" />
            </div>
          </div>
        )}

        {/* Loading indicator */}
        {loading && <LoadingIndicator />}

        {/* Endless scroll */}
        <EndlessScroll />

        {/* Error */}
        {error && (
          <div className="container">
            <div className="row">
              <div className="col-16 col-md-8 offset-md-1">
                <h3>
                  <FormattedMessage id="error.general" />
                </h3>
                <pre>{JSON.stringify(error, null, 2)}</pre>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        className="d-block d-xl-none open-filter-button"
        onClick={() => setFilterOpen((prev) => !prev)}
      >
        Filter
      </div>
    </section>
  );
};

// Wrap the Overview component with the OverviewProvider.
const ParagraphOverview = ({ content }) => {
  return (
    <OverviewProvider content={content}>
      <Overview />
    </OverviewProvider>
  );
};

ParagraphOverview.propTypes = {
  content: PropTypes.shape({
    fieldContentType: PropTypes.oneOf(["news", "event", "projekt", "person"]),
  }),
};

export default ParagraphOverview;
