import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import QueryExtendedTeaserView from "../query-overview.graphql";

// Context for OverviewProvider.

export const OverviewContext = React.createContext({});

// Provider for OverviewContext.
const OverviewProvider = ({ children, content }) => {
  const [currentFilters, setCurrentFilters] = useState({
    combine: "",
    field_design_filter_target_id: [],
    field_discipline_target_id: [],
    field_interior_filter_target_id: [],
    field_kategorie_target_id: [],
    field_region_target_id: [],
  });

  const [discipline, setDiscipline] = useState([]);

  // Mapping is used because this project is still using old content type naming but new overview component.
  const contentTypeMapping = {
    news: "news",
    veranstaltung: "event",
    projekt: "project",
    person: "person",
  };

  // The view ID is used to fetch the correct view.
  const viewId = `overview_${contentTypeMapping[content.fieldContentType]}`;

  const variables = {
    viewId,
    type: [content.fieldContentType],
    page: 0,
    title: currentFilters.title,
    fieldDisciplineTargetId: currentFilters.field_discipline_target_id,
    fieldKategorieTargetId: currentFilters.field_kategorie_target_id,
    fieldRegionTargetId: discipline.includes("architecture")
      ? currentFilters.field_region_target_id
      : [],
    fieldDesignTargetId: discipline.includes("design")
      ? currentFilters.field_design_filter_target_id
      : [],
    fieldInteriorTargetId: discipline.includes("interior")
      ? currentFilters.field_interior_filter_target_id
      : [],
    combine: currentFilters.combine,
  }

  // Fetch nodes based on current filters.
  // @see https://graphql-core-schema.netlify.app/schema-extensions/views.html
  const { data, loading, error, fetchMore } = useQuery(QueryExtendedTeaserView, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  // Extract nodes from the result.
  const nodes = data?.entityById.executable.execute.rows;

  // Extract meta data from the result.
  const metaData = {
    totalRows: data?.entityById.executable.execute.total_rows,
    perPage: data?.entityById.executable.pager.perPage,
  }

  // Exposed functions and values for the OverviewContext.
  const value = {
    viewId,
    contentType: content.fieldContentType,
    metaData,
    currentFilters,
    setCurrentFilters,
    discipline,
    setDiscipline,
    fetchMore,
    nodes,
    loading,
    error,
  };

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.shape({
    fieldContentType: PropTypes.string,
  }),
};

export default OverviewProvider;
