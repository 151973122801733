import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import WebformElements from "../webform-elements";

const Fieldset = ({ item, token, compositeIndex, compositeParent }) => {
  const { values } = useFormikContext();

  const { invisible, visible, optional, required } = states(
    item["#states"],
    values
  );

  return (
    <fieldset
      className={classNames({
        "form-group webform-fieldset": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <legend>
        {item["#title"]}
        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </legend>

      <WebformElements
        items={item.elements}
        token={token}
        compositeParent={compositeParent}
        compositeIndex={compositeIndex}
      />

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </fieldset>
  );
};

Fieldset.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    "#title": PropTypes.string,
    "#flex": PropTypes.number,
    "#required": PropTypes.object,
    "#states": PropTypes.array,
    "#description": PropTypes.string,
    elements: PropTypes.array,
  }),
  token: PropTypes.string.isRequired,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default Fieldset;
