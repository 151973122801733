import React from "react";
import PropTypes from "prop-types";

import {
  LogoFacebook32,
  LogoFlickr32,
  LogoInstagram32,
  LogoLinkedin32,
  LogoPinterest32,
  LogoTumblr32,
  LogoTwitter32,
  LogoXing32,
  LogoYoutube32,
} from "@carbon/icons-react";

export const SocialMedia = (props) => {
  return (
    <ul className="social-media">
      {props.footerConfig.fieldFacebook && (
        <li>
          <a
            className="facebook"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldFacebook.uri.path}
          >
            Facebook
          </a>
        </li>
      )}
      {props.footerConfig.fieldYoutube && (
        <li>
          <a
            className="youtube"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldYoutube.uri.path}
          >
            YouTube
          </a>
        </li>
      )}
      {props.footerConfig.fieldFlickr && (
        <li>
          <a
            className="flickr"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldFlickr.uri.path}
          >
            Flickr
          </a>
        </li>
      )}
      {props.footerConfig.fieldInstagram && (
        <li>
          <a
            className="instagram"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldInstagram.uri.path}
          >
            Instagram
          </a>
        </li>
      )}
      {props.footerConfig.fieldVimeo && (
        <li>
          <a
            className="vimeo"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldVimeo.uri.path}
          >
            Vimeo
          </a>
        </li>
      )}
      {props.footerConfig.fieldLinkedin && (
        <li>
          <a
            className="linkedin"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldLinkedin.uri.path}
          >
            LinkedIn
          </a>
        </li>
      )}
      {props.footerConfig.fieldPinterest && (
        <li>
          <a
            className="pinterest"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldPinterest.uri.path}
          >
            Pinterest
          </a>
        </li>
      )}
      {props.footerConfig.fieldTwitter && (
        <li>
          <a
            className="x"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldTwitter.uri.path}
          >
            X
          </a>
        </li>
      )}
      {props.footerConfig.fieldXing && (
        <li>
          <a
            className="xing"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldXing.uri.path}
          >
            Xing
          </a>
        </li>
      )}
      {props.footerConfig.fieldTumblr && (
        <li>
          <a
            className="tumblr"
            target="_blank"
            rel="noopener noreferrer"
            href={props.footerConfig.fieldTumblr.uri.path}
          >
            Tumblr
          </a>
        </li>
      )}
    </ul>
  );
};

SocialMedia.propTypes = {
  footerConfig: PropTypes.shape({
    fieldFacebook: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldYoutube: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldFlickr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldInstagram: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldVimeo: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldLinkedin: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldPinterest: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTwitter: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldXing: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
    fieldTumblr: PropTypes.shape({
      uri: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};
