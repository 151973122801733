import React from "react";
import PropTypes from "prop-types";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import TeaserBase from "../../../general-components/teaser-base/teaser-base";
import { teaserProjectPropTypes } from "../../../general-components/teaser-base/project/teaser-projekt";
import { FormattedMessage } from "react-intl";

const RelatedProjects = ({ content }) => {
  if (!content?.fieldRelatedProjects?.length) return null;

  return (
    <section className="paragraph paragraph-related-projects">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="headline-l text-center">
              <FormattedMessage id="related_projects" />
            </h2>
          </div>
        </div>
      </div>
      <div className="container">
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 575: 1, 767: 2, 1023: 3 }}
        >
          <Masonry gutter="20px">
            {content.fieldRelatedProjects.map((project, i) => (
              <div key={i}>
                <TeaserBase item={project} />
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </section>
  );
};

RelatedProjects.propTypes = {
  content: PropTypes.shape({
    fieldRelatedProjects: PropTypes.arrayOf(teaserProjectPropTypes),
  }),
};

export default RelatedProjects;
