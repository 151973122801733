import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base/teaser-base";
import ErrorBoundary from "../../../../error-boundary";
import { teaserPersonPropTypes } from "../../../teaser-base/person/teaser-person";

const ParagraphKontakt = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-kontakt": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div className="row">
              {content.fieldPersonen.map((item, index) => (
                <div
                  key={index}
                  className={classNames({
                    "col-16": true,
                    "col-lg-6": content.fieldBilderZeigen,
                    "offset-lg-1":
                      content.fieldBilderZeigen && index === 0,
                    "offset-lg-2": index !== 0,
                    "col-lg-3": !content.fieldBilderZeigen,
                    "offset-lg-4":
                      !content.fieldBilderZeigen && index === 0,
                  })}
                >
                  <ErrorBoundary>
                    <TeaserBase
                      showImage={content.fieldBilderZeigen}
                      item={item}
                      viewMode={"kontakt"}
                    />
                  </ErrorBoundary>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphKontakt.propTypes = {
  content: PropTypes.shape({
    fieldBilderZeigen: PropTypes.bool,
    fieldPersonen: PropTypes.arrayOf(teaserPersonPropTypes),
  }),
};

export default ParagraphKontakt;
