import React from "react";
import PropTypes from "prop-types";
import Loader from "react-loader-spinner";

const LoadingIndicator = ({ width = 50, className = ""}) => {
  return (
    <div className={`loading-indicator-wrap ${className}`}>
      <span>Loading</span>
    </div>
  );
};

LoadingIndicator.propTypes = {
  width: PropTypes.number,
  className: PropTypes.string,
};

export default LoadingIndicator;
