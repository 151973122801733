import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphNumberFacts = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-number-facts": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col d-flex align-items-center justify-content-center">
            <div className="number-fact main">
              <div className="number headline-xl">
                {content.fieldZahlenUndFakten[0].fieldZahl}
              </div>
              <div className="label">
                {content.fieldZahlenUndFakten[0].fieldLabel}
              </div>
            </div>
          </div>
          <div className="d-block d-md-none w-100" />
          {content.fieldZahlenUndFakten.length > 1 && (
            <>
              <div className="col col-md-8 small">
                {content.fieldZahlenUndFakten
                  .slice(1, content.fieldZahlenUndFakten.length)
                  .map((item, index) => (
                    <div className="number-fact d-table d-md-block" key={index}>
                      <div className="number headline-l">{item.fieldZahl}</div>
                      <div className="label">{item.fieldLabel}</div>
                    </div>
                  ))}
              </div>
              <div className="d-block d-md-none w-100" />
            </>
          )}
        </div>
      </div>
    </section>
  );
};

ParagraphNumberFacts.propTypes = {
  content: PropTypes.shape({
    fieldZahlenUndFakten: PropTypes.arrayOf(
      PropTypes.shape({
        fieldZahl: PropTypes.string.isRequired,
        fieldLabel: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default ParagraphNumberFacts;
