import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";
import { connect } from "react-redux";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";

const sliderSettings = {
  adaptiveHeight: false,
  arrows: true,
  centerMode: true,
  dots: false,
  infinite: true,
  slidesToShow: 3.66,
  slidesToScroll: 1,
  touchMove: false,
  nextArrow: <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />,
  prevArrow: <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2.66,
        touchMove: true,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1.66,
        touchMove: true,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        touchMove: true,
      },
    },
  ],
};

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class ComponentTeaserlistCarousel extends React.Component {
  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.entityQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.entityQuery.items,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.entityQuery) !==
      JSON.stringify(this.props.nodes.entityQuery)
    ) {
      this.pushPagerFullPageConfig();
    }
  }

  render() {
    if (this.props.nodes.loading) {
      return false;
    }

    return (
      <>
        {(this.props.nodesConfig === "Manuell" &&
          this.props.manualNodes.length >= 4) ||
        (this.props.nodesConfig !== "Manuell" &&
          this.props.nodes.entityQuery &&
          this.props.nodes.entityQuery.items.length >= 4) ? (
          <>
            {this.props.nodesConfig === "Manuell" ? (
              <Slider {...sliderSettings}>
                {this.props.manualNodes.map((item, index) => (
                  <React.Fragment key={index}>
                    {(() => {
                      switch (item.entity.entityBundle) {
                        case "news":
                          return (
                            <TeaserNews
                              item={item.entity}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        case "person":
                          return (
                            <TeaserPerson
                              item={item.entity}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </React.Fragment>
                ))}
              </Slider>
            ) : (
              <>
                {this.props.nodes.entityQuery &&
                this.props.nodes.entityQuery.items.length > 0 ? (
                  <>
                    <Slider {...sliderSettings}>
                      {this.props.nodes.entityQuery.items.map((item, index) => (
                        <React.Fragment key={index}>
                          {(() => {
                            switch (item.entityBundle) {
                              case "news":
                                return (
                                  <TeaserNews
                                    item={item}
                                    pagerFullPage={this.props.pagerFullPage}
                                  />
                                );
                              case "person":
                                return (
                                  <TeaserPerson
                                    item={item}
                                    pagerFullPage={this.props.pagerFullPage}
                                  />
                                );
                              default:
                                return null;
                            }
                          })()}
                        </React.Fragment>
                      ))}
                    </Slider>
                  </>
                ) : (
                  <LoadingIndicator />
                )}
              </>
            )}
          </>
        ) : null}
      </>
    );
  }
}

ComponentTeaserlistCarousel.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      teaserNewsPropTypes,
      teaserEventPropTypes,
      teaserPersonPropTypes,
      teaserGeneralPropTypes,
      teaserProjectPropTypes,
    ])
  ),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  pagerFullPageOverviewLink: PropTypes.object,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  nodes: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.array,
      total: PropTypes.number,
    }),
    loading: PropTypes.bool,
  }),
  dispatch: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell",
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tag: props.tags.map((item) => item.id.toString()),
        filterTagEnabled: props.tags.length > 0,
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(ComponentTeaserlistCarousel)
);

export { sliderSettings };
