import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphSectionheading = ({ content, subgrid = true }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-sectionheading": true,
    standalone: subgrid,
  });

  if (subgrid) {
    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              <h2 className="section-heading headline-m">{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className={sectionClassNames}>
        <h3 className="section-heading headline-m headline-secondary">{content.fieldHeading}</h3>
      </section>
    );
  }
};

ParagraphSectionheading.propTypes = {
  subgrid: PropTypes.bool,
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldSetAnchorLink: PropTypes.bool,
  }),
};

export default ParagraphSectionheading;
