import React from "react";
import PropTypes from "prop-types";
import { ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";
import WebformElements from "../webform-elements";

const Flexbox = ({ item, token, compositeIndex, compositeParent }) => {
  const { values } = useFormikContext();

  const { invisible, visible } = states(item["#states"], values);

  return (
    <div
      className={classNames({
        "form-group webform-flexbox": true,
        hidden: invisible || !visible,
      })}
    >
      <div
        style={
          item["#flex"]
            ? { flex: item["#flex"], alignItems: item["#align_items"] }
            : { alignItems: item["#align_items"] }
        }
        className="flexbox-wrap"
      >
        <WebformElements
          items={item.elements}
          token={token}
          compositeParent={compositeParent}
          compositeIndex={compositeIndex}
        />
      </div>

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </div>
  );
};

Flexbox.propTypes = {
  item: PropTypes.shape({
    "#align_items": PropTypes.string,
    "#default_value": PropTypes.string,
    "#description": PropTypes.string,
    "#flex": PropTypes.string,
    "#maxlength": PropTypes.number,
    "#minlength": PropTypes.number,
    "#placeholder": PropTypes.string,
    "#required": PropTypes.bool,
    "#required_error": PropTypes.string,
    "#states": PropTypes.object,
    "#submit__label": PropTypes.string,
    "#title": PropTypes.string,
    "#type": PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    elements: PropTypes.array,
  }),
  token: PropTypes.string.isRequired,
  compositeIndex: PropTypes.number,
  compositeParent: PropTypes.string,
};

export default Flexbox;
