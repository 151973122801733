import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";

import EditButton from "../../../backend/edit-button";

import Image from "../../image/image";
import { FormattedMessage } from "react-intl";

const TeaserPerson = (props) => {
  const { adminApp } = useSelector((reduxStore) => ({
    adminApp: reduxStore.appStore.adminApp,
  }));

  return (
    <article className="node node-teaser teaser-person">
      <EditButton
        adminApp={adminApp}
        entityId={props.item.id}
        destinationRoute={props.location.pathname}
      />
      {props.item.fieldBildWCaptionRawField && (
        <Image
          data={
            props.item.fieldBildWCaptionRawField.first.entity.fieldMediaImage
          }
          nodeTitle={props.item.title}
        />
      )}
      <div className="text person-details">
        <h3 className="headline-m headline-secondary">{props.item.title}</h3>
        {props.item.fieldJob && <p>{props.item.fieldJob}</p>}
        {props.item.fieldDiscipline && <p className="discipline">{props.item.fieldDiscipline.map(item => item.label).join(", ")}</p>}
        {props.item.fieldAttachment && (
          <a
            data-entity-type="media"
            target="_blank"
            rel="noreferrer"
            href={props.item.fieldAttachment.mediaFileUrl.path}
          >
            <FormattedMessage id="cv" />
          </a>
        )}
      </div>
    </article>
  );
};

export const teaserPersonPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  fieldMail: PropTypes.string,
  fieldTelefon: PropTypes.string,
  fieldMobil: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldBeschreibung: PropTypes.string,
  fieldKontakinformationen: PropTypes.arrayOf(
    PropTypes.shape({
      fieldContactType: PropTypes.string.isRequired,
      fieldWert: PropTypes.string.isRequired,
    })
  ),
  fieldRolleOderFunktion: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldBildWCaptionRawField: PropTypes.shape({
    first: PropTypes.shape({
      customCaption: PropTypes.string,
      defaultCaption: PropTypes.bool,
      entity: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
          style: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    }),
  }),
  fieldAttachment: PropTypes.shape({
    mediaFileUrl: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
});

TeaserPerson.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: teaserPersonPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default withRouter(TeaserPerson);
