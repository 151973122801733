import React from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import Image from "../../image/image";
import { useSelector } from "react-redux";
import EditButton from "../../../backend/edit-button";
import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import Hyphenate from "../../hyphenate";

const TeaserProjekt = ({ item, location }) => {
  const adminApp = useSelector((reduxStore) => reduxStore.appStore.adminApp);

  const htgReveal = keyframes`
    from {
      opacity: 0;
      transform: translateY(50px);
    }
  
    to {
      opacity: 1;
      transform: translateY(0);
    }
  `;

  // Calculate date range and filter duplicates and undefined values.
  const allDates = [
    +item.fieldPlanning?.value.split("-")[0],
    +item.fieldStartOfConstruction?.value.split("-")[0],
    +item.fieldMarketLaunch?.value.split("-")[0],
    +item.fieldCompetition?.value.split("-")[0],
    +item.fieldCompletion?.value.split("-")[0],
  ].filter(Boolean);

  const dateStart = Math.min(...allDates);
  const dateEnd = Math.max(...allDates);
  const date = [
    ...new Set(
      [
        dateStart !== Infinity ? +dateStart : undefined,
        dateEnd !== Infinity ? +dateEnd : undefined,
      ].filter(Boolean)
    ),
  ].join(" - ");

  return (
    <Reveal keyframes={htgReveal} triggerOnce>
      <article className="node node-teaser teaser-project">
        <EditButton
          adminApp={adminApp}
          entityId={item.id}
          destinationRoute={location?.pathname}
        />
        <Link
          className="flex-wrap"
          to={item.url ? item.url.path : item.path.alias}
        >
          {item.fieldImages.length && (
            <div className="image-wrapper">
              <Image data={item.fieldImages[0].fieldMediaImage} lqip />
            </div>
          )}
          <div className="info-wrapper">
            <h3 className="headline-m">
              <Hyphenate>{item.title}</Hyphenate>
            </h3>
            <div>
              {item.fieldSubheader && (
                <p className="headline-m headline-secondary">
                  <Hyphenate>{item.fieldSubheader}</Hyphenate>
                </p>
              )}
              {item.fieldClientShort && (
                <p className="headline-m headline-secondary">
                  {item.fieldClientShort}
                </p>
              )}
              <p className="headline-m headline-secondary">
                {/* {[item.fieldCity, item.fieldRegion?.name]
                  .filter(Boolean)
                  .join(", ")} */}
                {item.fieldCity}
              </p>
              {date && <p className="headline-m headline-secondary">{date}</p>}
            </div>
          </div>
          {/* <div className="tags">
          {item.fieldSchlagwort.length >= 1 &&
            item.fieldSchlagwort.map((item, index) => (
              <React.Fragment key={index}>
                {item !== null && <span className="tag">{item.name}</span>}
              </React.Fragment>
            ))}
        </div> */}
        </Link>
      </article>
    </Reveal>
  );
};

export const teaserProjectPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTeaserbild: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  fieldStartOfConstruction: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldPlanning: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldCompletion: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldCompetition: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldMarketLaunch: PropTypes.shape({
    value: PropTypes.string,
  }),
  fieldCity: PropTypes.string,
  fieldRegion: PropTypes.shape({
    name: PropTypes.string,
  }),
  fieldSubheader: PropTypes.string,
  fieldImages: PropTypes.array,
  fieldClientShort: PropTypes.string,
});

TeaserProjekt.propTypes = {
  item: teaserProjectPropTypes,
  location: PropTypes.object.isRequired,
  /* pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired, */
};

export default withRouter(TeaserProjekt);
