import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserBase from "../../../teaser-base/teaser-base";
import ErrorBoundary from "../../../../error-boundary";
import { teaserEventPropTypes } from "../../../teaser-base/event/teaser-event";

const ParagraphKalenderEinzelDoppel = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-kalender-einzel-doppel": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            {content.fieldHeading && <h2>{content.fieldHeading}</h2>}
          </div>
        </div>
      </div>

      <div className="row">
        {content.fieldEvents.map((item, index) => (
          <div
            key={index}
            className={classNames({
              "col-16": true,
              "col-md-14 offset-md-1": content.fieldEvents.length === 1,
              "col-md-6 offset-md-1":
                content.fieldEvents.length > 1 && index === 0,
              "col-md-6 offset-md-2":
                content.fieldEvents.length > 1 && index === 1,
            })}
          >
            <ErrorBoundary>
              <TeaserBase
                item={item}
                viewMode={"doppel-einzel"}
                single={content.fieldEvents.length === 1}
              />
            </ErrorBoundary>
          </div>
        ))}
      </div>
    </section>
  );
};

ParagraphKalenderEinzelDoppel.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldEvents: PropTypes.arrayOf(teaserEventPropTypes),
  }),
};

export default ParagraphKalenderEinzelDoppel;
