import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronDown24, ChevronUp24 } from "@carbon/icons-react";

const ParagraphStrukturierteListe = ({ content }) => {
  const [open, setOpen] = useState(false);

  const sectionClassNames = classNames({
    "paragraph paragraph-strukturierte-liste": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-15 offset-md-1">
            <div className="row">
              <div className="col-16 col-md-13">
                <h3>{content.fieldHeading}</h3>
              </div>
              {content.fieldAusklappenEinklappen && (
                <div className="col-16 col-md-3 d-md-flex justify-content-end align-items-baseline">
                  <div
                    className="btn btn-secondary btn-icon"
                    onClick={() => setOpen(prev => !prev)}
                  >
                    {open ? (
                      <>
                        Einklappen <ChevronUp24 />
                      </>
                    ) : (
                      <>
                        Ausklappen <ChevronDown24 />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              className={classNames({
                "row content": true,
                "d-none": !open,
                "d-flex":
                  open ||
                  !content.fieldAusklappenEinklappen,
              })}
            >
              {content.fieldListen.map((item, index) => (
                <div
                  key={index}
                  className={classNames({
                    "list-item": true,
                    "col-16 col-lg-14":
                      content.fieldListen.length === 1,
                    "col-16 col-lg-8":
                      content.fieldListen.length > 1,
                  })}
                >
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {content.fieldHint && (
        <div
          className={classNames({
            "hint text": true,
            "d-none": !open,
            "d-flex":
              open || !content.fieldAusklappenEinklappen,
          })}
        >
          <div className="container">
            <div className="row">
              <div className="col-16">
                <div
                  dangerouslySetInnerHTML={{
                    __html: content.fieldHint,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

ParagraphStrukturierteListe.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldAusklappenEinklappen: PropTypes.bool,
    fieldHint: PropTypes.string,
    fieldListen: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default ParagraphStrukturierteListe;
