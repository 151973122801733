import moment from "moment";
import { self } from "../config";
import PropTypes from "prop-types";

/**
 * Takes information about an event and returns a data-encoded iCal.
 * @param {Object} datetime The datetime object holds the start and end timestamp (direclty from drupal "value, endValue")
 * @param {String} datetime.value The start timestamp of the event
 * @param {String} datetime.endValue The end timestamp of the event
 * @param {String} location The location of the event
 * @param {String} title The title of the event
 * @param {String} description An optional description for the event
 * @returns {String} data-encoded iCal-file
 */
const getEncodedIcalUrl = (datetime, location, title, description = "") => {
  if (!datetime || !title) return;

  // format the date object to fit into the ical requirements
  const startTimestamp = moment(datetime.value).format("YYYYMMDDThhmmss") + "Z";
  const endTimestamp =
    moment(datetime.endValue).format("YYYYMMDDThhmmss") + "Z";

  // generate current timestamp for UID
  const currentTimestamp = moment().format("YYYYMMDDThhmmss") + "Z";

  const icalBaseObject = {
    UID: currentTimestamp + "@" + self,
    CREATED: currentTimestamp,
    DTSTAMP: startTimestamp,
    DTSTART: startTimestamp,
    DTEND: endTimestamp,
    SUMMARY: title,
    DESCRIPTION: description,
    LOCATION: location,
  };

  const icalString =
    [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "PRODID:TEST",
      "BEGIN:VEVENT",
      `UID:${icalBaseObject.UID}`,
      `CREATED:${icalBaseObject.CREATED}`,
      `DTSTAMP:${icalBaseObject.DTSTAMP}`,
      `DTSTART:${icalBaseObject.DTSTART}`,
      `DTEND:${icalBaseObject.DTEND}`,
      `SUMMARY:${icalBaseObject.SUMMARY}`,
      `DESCRIPTION:${icalBaseObject.DESCRIPTION}`,
      `LOCATION:${icalBaseObject.LOCATION}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\r\n") + "\r\n";

  // encode the ical string in base64
  return "data:text/calendar," + encodeURIComponent(icalString);
};

getEncodedIcalUrl.propTypes = {
  datetime: PropTypes.shape({
    value: PropTypes.string.isRequired,
    endValue: PropTypes.string.isRequired,
  }),
  location: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default getEncodedIcalUrl;
