import React from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { connect } from "react-redux";
import classNames from "classnames";

import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../../loading-indicator";
import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";
import { pagerFullPagesAction } from "../../../../../app-actions";
import TeaserBase from "../../../../teaser-base/teaser-base";
import { teaserEventPropTypes } from "../../../../teaser-base/event/teaser-event";
import { teaserGeneralPropTypes } from "../../../../teaser-base/general/teaser-general";
import { teaserProjectPropTypes } from "../../../../teaser-base/project/teaser-projekt";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

class ComponentTeaserlistWrap extends React.Component {
  pushPagerFullPageConfig = () => {
    if (this.props.pagerFullPage && this.props.nodes.entityQuery) {
      const pagerFullPagesConfig = {
        id: this.props.id,
        items:
          this.props.nodesConfig === "Manuell"
            ? this.props.manualNodes.map((item) => item.entity)
            : this.props.nodes.entityQuery.items,
        overviewLink: this.props.pagerFullPageOverviewLink,
      };

      this.props.dispatch(pagerFullPagesAction(pagerFullPagesConfig));
    }
  };

  componentDidMount() {
    // Pager on full screen pages.
    this.pushPagerFullPageConfig();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.nodes.entityQuery) !==
      JSON.stringify(this.props.nodes.entityQuery)
    ) {
      this.pushPagerFullPageConfig();
    }
  }

  render() {
    return (
      <>
        {this.props.nodesConfig === "Manuell" ? (
          <>
            {/* TODO: This does not work */}
            {this.props.manualNodes.map((item, index) => (
              <div
                key={index}
                className={classNames({
                  [`col-${32 / this.props.manualNodes.length} col-lg-${
                    16 / this.props.manualNodes.length
                  }`]: this.props.manualNodes.length !== 3,
                  "col-16 col-lg-5":
                    this.props.manualNodes.length === 3 && index !== 0,
                  "col-16 col-lg-5 offset-lg-1":
                    this.props.manualNodes.length === 3 && index === 0,
                })}
              >
                {(() => {
                  switch (item.entity.entityBundle) {
                    case "news":
                      return (
                        <TeaserNews
                          item={item.entity}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    case "person":
                      return (
                        <TeaserPerson
                          count={this.props.count}
                          item={item.entity}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    case "veranstaltung":
                      return (
                        <TeaserBase
                          item={item.entity}
                          single={false}
                          viewMode={"teaserlist"}
                          pagerFullPage={this.props.pagerFullPage}
                        />
                      );
                    default:
                      return null;
                  }
                })()}
              </div>
            ))}
          </>
        ) : (
          <>
            {this.props.nodes.entityQuery &&
            this.props.nodes.entityQuery.items.length > 0 ? (
              <>
                {this.props.nodes.entityQuery.items.map((item, index) => (
                  // Would this not just be 'col-16 col-lg' ?
                  <div
                    key={index}
                    className={classNames({
                      [`col-${32 / this.props.count} col-lg-${
                        16 / this.props.count
                      }`]: this.props.count !== 3,
                      "col-16 col-lg-5": this.props.count === 3 && index !== 0,
                      "col-16 col-lg-5 offset-lg-1":
                        this.props.count === 3 && index === 0,
                    })}
                  >
                    {(() => {
                      switch (item.entityBundle) {
                        case "news":
                          return (
                            <TeaserNews
                              item={item}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        case "person":
                          return (
                            <TeaserPerson
                              count={this.props.count}
                              item={item}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        case "veranstaltung":
                          return (
                            <TeaserBase
                              item={item}
                              single={false}
                              viewMode={"teaserlist"}
                              pagerFullPage={this.props.pagerFullPage}
                            />
                          );
                        default:
                          return null;
                      }
                    })()}
                  </div>
                ))}
              </>
            ) : (
              <LoadingIndicator />
            )}
          </>
        )}
      </>
    );
  }
}

ComponentTeaserlistWrap.propTypes = {
  count: PropTypes.number.isRequired,
  manualNodes: PropTypes.arrayOf([
    teaserNewsPropTypes,
    teaserEventPropTypes,
    teaserPersonPropTypes,
    teaserGeneralPropTypes,
    teaserProjectPropTypes,
  ]),
  id: PropTypes.string.isRequired,
  nodesConfig: PropTypes.oneOf(["Automatisch (chronologisch)", "Manuell"]),
  type: PropTypes.oneOf(["news", "person", "veranstaltung", "all"]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  nodes: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.array,
      total: PropTypes.number,
    }),
    loading: PropTypes.bool,
  }),
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
  pagerFullPageOverviewLink: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(
  graphql(teaserNodeQueryFilterTag, {
    name: "nodes",
    skip: (props) => props.nodesConfig === "Manuell",
    options: (props) => ({
      variables: {
        limit: props.count ? props.count : 100,
        type: props.type === "all" ? ["news", "person"] : [props.type],
        tag: props.tags.map((item) => item.id),
        filterTagEnabled: props.tags.length > 0,
        language: props.currentLanguage.toUpperCase(),
      },
    }),
  })(ComponentTeaserlistWrap)
);
