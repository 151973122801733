import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { useQuery } from "@apollo/client";
import teaserNodeQueryFilterTag from "../../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../../teaser-base/person/teaser-person";

const ParagraphTeaserListPersons = ({ content }) => {
  const mode = content.fieldAutmatischOderManuell;

  const [loading, setLoading] = useState(mode === "auto");
  const [nodes, setNodes] = useState(
    mode === "manual" ? content.fieldBeitraegePerson : []
  );
  console.log(content);

  useQuery(teaserNodeQueryFilterTag, {
    skip: mode === "manual",
    fetchPolicy: "network-only",
    variables: {
      tag:
        content.fieldSchlagwort.length &&
        content.fieldSchlagwort.map((item) => item.id),
      filterTagEnabled: !!content.fieldSchlagwort.length,
      limit: 100,
      type: "person",
    },
    onError: (error) => {
      console.error(error);
      setLoading(false);
    },
    onCompleted: (data) => {
      setNodes(data.entityQuery.items);
      setLoading(false);
    },
  });

  return (
    <section className="paragraph paragraph-teaser-list-persons">
      <div className="container">
        <Accordion allowZeroExpanded={true}>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                <h2 className="headline-l">{content.fieldHeading}</h2>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {loading && <p>Loading ...</p>}
              {!loading && !nodes.length && <p>No Results</p>}
              {!loading && !!nodes.length && (
                <div className="row teasers-wrapper">
                  {nodes.map((node, i) => (
                    <div key={i} className="col-6 col-lg-3">
                      <TeaserPerson item={node} />
                    </div>
                  ))}
                </div>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  );
};

ParagraphTeaserListPersons.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldAutmatischOderManuell: PropTypes.oneOf(["manual", "auto"]).isRequired,
    fieldSchlagwort: PropTypes.array,
    fieldBeitraegePerson: PropTypes.arrayOf(
      PropTypes.shape(teaserPersonPropTypes)
    ),
  }),
};

export default ParagraphTeaserListPersons;
