import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";
import { ArrowRight32, ArrowLeft32 } from "@carbon/icons-react";

import Link from "../../../link/link";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphTeaserSlider = ({ content }) => {
  const sliderSettings = {
    arrows: true,
    dots: false,
    nextArrow: <ArrowRight32 width={48} height={48} viewBox="0 0 32 24" />,
    prevArrow: <ArrowLeft32 width={48} height={48} viewBox="0 0 32 24" />,
    slidesToShow: 1,
    slidesToScroll: 1,
    touchMove: false,
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-slider": true,
  });

  return (
    <section className={sectionClassNames}>
      <ErrorBoundary>
        <Slider {...sliderSettings}>
          {content.fieldTeaserSlides.map((item, index) => (
            <React.Fragment key={index}>
              <ErrorBoundary>
                <Image
                  className="background-image"
                  data={item.fieldImage.fieldMediaImage}
                />
              </ErrorBoundary>
              <div>
                <article className="container">
                  <div className="row">
                    <div className="col-16 position-static">
                      {item.fieldTeaserueberschrift ? (
                        <h3>{item.fieldTeaserueberschrift}</h3>
                      ) : (
                        <h3>{item.fieldInhaltLink.uri.entity.title}</h3>
                      )}
                      <Link
                        className="btn btn-primary"
                        link={item.fieldInhaltLink}
                      />
                      <p className="pager d-none d-md-block">
                        Slide {index + 1} /{" "}
                        {content.fieldTeaserSlides.length}
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </React.Fragment>
          ))}
        </Slider>
      </ErrorBoundary>
    </section>
  );
};

ParagraphTeaserSlider.propTypes = {
  content: PropTypes.shape({
    fieldTeaserSlides: PropTypes.arrayOf(
      PropTypes.shape({
        fieldTeaserueberschrift: PropTypes.string.isRequired,
        fieldImage: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            style: PropTypes.shape({
              url: PropTypes.string.isRequired,
            }),
          }),
        }),
        fieldInhaltLink: PropTypes.shape({
          title: PropTypes.string,
          uri: PropTypes.shape({
            __typename: PropTypes.string,
            path: PropTypes.string,
            entity: PropTypes.shape({
              title: PropTypes.string,
            }),
          }),
        }),
      })
    ),
  }),
};

export default ParagraphTeaserSlider;
