import React, { useEffect, useRef } from "react";
import useOverviewContext from "../store/use-overview-context";

/**
 * Component for implementing endless scrolling behavior.
 * @returns {JSX.Element} The EndlessScroll component.
 */
const EndlessScroll = () => {
  // Get relevant values and functions from the OverviewContext.
  const { setPageMode, metaData, nodes, loading, setCurrentPage, currentFilters } =
    useOverviewContext();

  const scrollRef = useRef();

  useEffect(() => {
    // Set the page mode to endless-scroll for correct fetching.
    setPageMode("endless-scroll");

    const triggerElement = scrollRef.current;

    // Check if the element is intersecting with the viewport.
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry.isIntersecting &&
          !loading &&
          nodes.length < metaData.totalRows &&
          nodes.length !== 0
        ) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      },
      {
        threshold: 0,
        rootMargin: "3000px",
      }
    );

    if (triggerElement) {
      observer.observe(triggerElement);
    }

    // Clean up function.
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [currentFilters, nodes]);

  return <div ref={scrollRef} />;
};

export default EndlessScroll;
