const getFieldNameAndValue = (rawName, rawValue) => {
  let fieldName = rawName.split("=")[1].replace(/"/g, "").replace(/]/g, "");
  let fieldValue = rawValue;

  if (fieldName.includes("[")) {
    // Return everything before the "["
    const fieldNameSplitted = fieldName.split("[");
    fieldName = fieldNameSplitted[0];
    fieldValue = fieldNameSplitted[1];
  }

  return { fieldName, fieldValue };
};

/**
 * Transforms states from graphql v4 states to graphql v3 graphql_webform
 * states.
 *
 * @param statesV4
 */
const transformStates = (statesV4) => {
  const states = {};
  for (const stateName in statesV4) {
    // 'XOR' is handled as 'OR'.
    const logic = Array.isArray(statesV4[stateName]) ? "OR" : "AND";

    let conditions = [];

    if (logic === "OR") {
      conditions = statesV4[stateName]
        .filter((item) => typeof item === "object")
        .map((item) => {
          const itemEntries = Object.entries(item);

          const { fieldName, fieldValue } = getFieldNameAndValue(
            itemEntries[0][0],
            Object.entries(itemEntries[0][1])[0][1]
          );

          return {
            // selector string is e.g. ":input[name="name"]", we want just the
            // field name, also "name" in this case.
            field: fieldName,
            selector: itemEntries[0][0],
            trigger: Object.entries(itemEntries[0][1])[0][0],
            fieldValue: fieldValue,
          };
        });
    } else {
      for (const [key, value] of Object.entries(statesV4[stateName])) {
        const { fieldName, fieldValue } = getFieldNameAndValue(
          key,
          Object.entries(value)[0][1]
        );

        conditions = [
          ...conditions,
          {
            field: fieldName,
            selector: key,
            trigger: Object.entries(value)[0][0],
            fieldValue: fieldValue,
          },
        ];
      }
    }

    states[stateName] = {
      logic,
      conditions,
    };
  }

  return states;
};

export const states = (states, values) => {
  let invisible = false;
  let visible = true;
  let enabled = true;
  let disabled = false;
  let optional = false;
  let required = false;

  states = transformStates(states);

  if (states) {
    for (const stateName in states) {
      if (states[stateName] && stateName !== "__typename") {
        const state = states[stateName];
        let conditionEvaluation = [];

        state.conditions.forEach((item) => {
          switch (item.trigger) {
            case "empty": {
              if (values[item.field] === "") {
                conditionEvaluation = [...conditionEvaluation, true];
              } else {
                conditionEvaluation = [...conditionEvaluation, false];
              }

              break;
            }
            case "checked": {
              // Single Checkbox.
              if (typeof values[item.field] === "boolean") {
                conditionEvaluation = [
                  ...conditionEvaluation,
                  values[item.field],
                ];
              } else {
                if (values[item.field].includes(item.fieldValue)) {
                  conditionEvaluation = [...conditionEvaluation, true];
                } else {
                  conditionEvaluation = [...conditionEvaluation, false];
                }
              }
              break;
            }
            case "unchecked": {
              if (!values[item.field].includes(item.fieldValue)) {
                conditionEvaluation = [...conditionEvaluation, true];
              } else {
                conditionEvaluation = [...conditionEvaluation, false];
              }

              break;
            }
            case "filled": {
              if (values[item.field] !== "") {
                conditionEvaluation = [...conditionEvaluation, true];
              } else {
                conditionEvaluation = [...conditionEvaluation, false];
              }

              break;
            }
            case "value": {
              if (values[item.field] === item.fieldValue) {
                conditionEvaluation = [...conditionEvaluation, true];
              } else {
                conditionEvaluation = [...conditionEvaluation, false];
              }

              break;
            }
            case "!value": {
              if (values[item.field] !== item.fieldValue) {
                conditionEvaluation = [...conditionEvaluation, true];
              } else {
                conditionEvaluation = [...conditionEvaluation, false];
              }

              break;
            }
          }
        });

        switch (state.logic) {
          case "AND": {
            if (stateName === "invisible") {
              invisible =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "visible") {
              visible =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "enabled") {
              enabled =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "disabled") {
              disabled =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "optional") {
              optional =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }
            if (stateName === "required") {
              required =
                conditionEvaluation.filter((item) => item).length ===
                conditionEvaluation.length;
            }

            break;
          }
          case "XOR":
          case "OR": {
            invisible =
              stateName === "invisible"
                ? conditionEvaluation.filter((item) => item).length > 0
                : false;
            visible =
              stateName === "visible"
                ? conditionEvaluation.filter((item) => item).length > 0
                : true;
            enabled =
              stateName === "enabled"
                ? conditionEvaluation.filter((item) => item).length > 0
                : true;
            disabled =
              stateName === "disabled"
                ? conditionEvaluation.filter((item) => item).length > 0
                : false;
            optional =
              stateName === "optional"
                ? conditionEvaluation.filter((item) => item).length > 0
                : true;
            required =
              stateName === "required"
                ? conditionEvaluation.filter((item) => item).length > 0
                : false;

            break;
          }
        }
      }
    }
  }

  if (invisible) {
    visible = false;
  }
  if (optional) {
    required = false;
  }

  if (required) {
    optional = false;
  }

  return { invisible, visible, enabled, disabled, optional, required };
};
