import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../general-components/loading-indicator";
import ContentBase from "../../general-components/content-base/content-base";
import landingPageQuery from "./landingpage.graphql";
import PagerFullPage from "../../general-components/pager-full-page";
import { useI18n } from "../../i18n/use-i18n";
import { useQuery } from "@apollo/client";
import Error404 from "../../system/error-404";
import Error500 from "../../system/error-500";
import { useCanonicalOrRedirect } from "../../hooks/use-canonical-or-redirect";
import { motion } from "framer-motion";

const Landingpage = (props) => {
  /**
   * Hook to get information from Redux Store
   */

  const pagerFullPageContextId = useSelector(
    (state) => state.appStore.pagerFullPageContextId
  );
  const pagerConfig = useSelector((state) => state.appStore.pagerFullPages);
  const microSite = useSelector((state) => state.appStore.microSite);
  const microSiteFrontPage = useSelector(
    (state) => state.appStore.microSiteFrontPage
  );

  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  // @todo: language variations are not EntityCanonicalUrl but RedirectUrl
  const { data, loading, error } = useQuery(landingPageQuery, {
    variables: {
      path:
        props.location.pathname === "/" &&
        microSite &&
        microSiteFrontPage !== ""
          ? microSiteFrontPage
          : props.location.pathname,
    },
  });

  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  useI18n(route?.languageSwitchLinks);

  // Return the loading indicator while loading
  if (loading) {
    return <LoadingIndicator className="page-loading-indicator" />;
  }

  // Return the 500 error component if query was not successful
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but data is empty
  if (!entity && !loading) {
    return <Error404 />;
  }

  const published = entity.status ? "node-published" : "node-unpublished";

  return (
    <motion.article
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}article
      className={`node-${entity.entityBundle} node-${entity.id} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`Hadi Teherani | ${entity.label}`}</title>
      </Helmet>

      {entity.fieldModules && <ContentBase content={entity.fieldModules} />}

      {pagerFullPageContextId &&
        props.pagerConfig.filter(
          (config) => config.id === pagerFullPageContextId
        ).length > 0 && (
          <PagerFullPage
            config={
              pagerConfig.filter(
                (config) => config.id === pagerFullPageContextId
              )[0]
            }
            currentId={entity.entityId}
          />
        )}
    </motion.article>
  );
};

Landingpage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default withRouter(Landingpage);
