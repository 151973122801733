import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ErrorBoundary from "../../../../error-boundary";
import ImageSlider from "../../../image-slider/image-slider";

const ParagraphBilderSlider = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-bilder-slider": true,
    [`format-${content.fieldFormat}`]: true,
  });

  const images = content.fieldBilderWCaptionRawField.list;

  return (
    <section className={sectionClassNames}>
      {content.fieldFormat === "full" ? (
        <ErrorBoundary>
          <ImageSlider images={images} />
        </ErrorBoundary>
      ) : (
        <div className="container">
          <div className="row">
            <div
              className={classNames({
                "col-12": true,
                "col-lg-6 offset-lg-3": content.fieldFormat === "center",
                "col-lg-6": content.fieldFormat === "left",
                "col-lg-6 offset-lg-6": content.fieldFormat === "right",
              })}
            >
              <ErrorBoundary>
                <ImageSlider images={images} globalCaption={content.fieldBildunterschrift} />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

ParagraphBilderSlider.propTypes = {
  content: PropTypes.shape({
    fieldBildunterschrift: PropTypes.string,
    fieldBilderWCaptionRawField: PropTypes.shape({
      list: PropTypes.arrayOf(
        PropTypes.shape({
          customCaption: PropTypes.string,
          defaultCaption: PropTypes.bool,
          entity: PropTypes.shape({
            fieldMediaImage: PropTypes.shape({
              alt: PropTypes.string,
              title: PropTypes.string,
              style: PropTypes.shape({
                url: PropTypes.string.isRequired,
              }),
            }),
          }),
        })
      ),
    }),
    fieldFormat: PropTypes.oneOf(["center", "left", "right", "full"]),
  }),
};

export default ParagraphBilderSlider;
