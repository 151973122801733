import { useQuery } from "@apollo/client";
import configQuery from "../general-components/config-query.graphql";

/**
 * Uses the useQuery hook to explicitly get a config page by name. Returns reduced data as an object.
 * @param {String} name Config page machine name.
 * @returns 
 */
const useConfigQuery = (name) => {
  const { data, loading, error } = useQuery(configQuery, {
    variables: {
      name,
    },
  });

  return { data: data?.entityQuery.items[0], loading, error };
};

export default useConfigQuery;