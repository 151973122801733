import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Download24 } from "@carbon/icons-react";
import Image from "../../../image/image";

const ParagraphDownload = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-download": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-15 offset-md-1">
            <div className="row">
              {!!content.fieldDatei.fieldVorschaubild?.fieldMediaImage && (
                <div className="col-2">
                  <Image
                    data={content.fieldDatei.fieldVorschaubild.fieldMediaImage}
                  />
                </div>
              )}
              <div
                className={classNames({
                  "col-10": content.fieldDatei.fieldVorschaubild === null,
                  "col-8": content.fieldDatei.fieldVorschaubild !== null,
                })}
              >
                <h3>{content.fieldDatei.label}</h3>
                {content.fieldDatei.fieldBeschreibung && (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: content.fieldDatei.fieldBeschreibung,
                    }}
                  />
                )}
              </div>
              <div className="col-6 d-flex justify-content-end">
                <a
                  className="btn btn-primary"
                  href={content.fieldDatei.mediaFileUrl.path}
                  download={true}
                >
                  <Download24 />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphDownload.propTypes = {
  content: PropTypes.shape({
    fieldDatei: PropTypes.shape({
      fieldVorschaubild: PropTypes.shape({
        fieldMediaImage: PropTypes.shape({
          alt: PropTypes.string,
          title: PropTypes.string,
        }),
      }),
      label: PropTypes.string.isRequired,
      fieldBeschreibung: PropTypes.string,
      mediaFileUrl: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }),
};

export default ParagraphDownload;
