const useCanonicalOrRedirect = (routeQuery) => {
  if (!routeQuery?.route) {
    return null;
  }

  return routeQuery.route.entity
    ? routeQuery.route
    : routeQuery.route.redirect.redirectRedirect.uri;
};

export { useCanonicalOrRedirect };
