import React, { Component } from "react";
import PropTypes from "prop-types";
import memoize from "memoize-one";

// Router
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Redux
import { connect } from "react-redux";

// i18n
import { IntlProvider } from "react-intl";
import messages_de from "./i18n/translations/de.json";
import messages_en from "./i18n/translations/en.json";

// Page Components
import Landingpage from "./nodes/landingpage/landingpage";
import Event from "./nodes/event/event";
// import Person from "./nodes/person/person";
import PageFooter from "./footer/page-footer";
import Header from "./header/header";
import ErrorBoundary from "./error-boundary";
import NodeRevision from "./backend/NodeRevision";
import WebformPage from "./intern/webform-page/webform-page";
import ResetPasswordWithTempPasswordForm from "./intern/reset-password-with-temp-password-form";
import ForgotPasswordForm from "./intern/forgot-password-form";
import LoginForm from "./intern/login-form";
import project from "./nodes/project/project";
import News from "./nodes/news/news";
import LogoButton from "./general-components/logo-button";
import { AnimatePresence } from "framer-motion";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {Object} - Relevant Data for App Component from Redux Store.
 */
const mapStateToProps = (reduxStore) => ({ reduxStore });

export const defaultLocale = "de",
  defaultMessages = messages_de;

const messages = {
  de: messages_de,
  en: messages_en,
};

/**
 * @todo 404.
 * @todo Fetch Page title and push to store. Use for <title> tag.
 */
class App extends Component {
  state = {
    headerClasses: "",
  };
  /**
   * Generate Base Class names for main wrapper.
   * @see https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#what-about-memoization
   *
   * @param {string} pathname - Current Path
   * @returns {string} - Base Classes
   */
  generateMainClasses = memoize((pathname) => {
    const pathNameSplitted = pathname.split("/");

    // Remove first (empty) item
    pathNameSplitted.shift();

    return `${pathNameSplitted[0]} ${pathNameSplitted
      .splice(1, pathNameSplitted.length)
      .join("-")}`;
  });

  componentDidMount() {
    document.body.className += `${this.generateMainClasses(
      this.props.location.pathname
    )}`;
  }

  componentDidUpdate(prevProps) {
    // Scroll to top on route change
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.hash
    ) {
      document.body.className = `${this.generateMainClasses(
        this.props.location.pathname
      )}`;
    }

    if (
      this.props.location !== prevProps.location &&
      this.props.location.hash
    ) {
      setTimeout(() => {
        const element = document.querySelector(this.props.location.hash);
        element.scrollIntoView();
      }, 500);
    }
  }

  render() {
    return (
      <IntlProvider
        locale={
          this.props.reduxStore.i18n.currentLanguage !== "und"
            ? this.props.reduxStore.i18n.currentLanguage
            : defaultLocale
        }
        defaultLocale={defaultLocale}
        messages={
          this.props.reduxStore.i18n.currentLanguage !== "und"
            ? messages[this.props.reduxStore.i18n.currentLanguage]
            : defaultMessages
        }
      >
        <div className={`main-content ${this.state.headerClasses}`}>
          <div className="logo-wrapper logo-wrapper-mobile d-flex justify-content-center align-items-center d-lg-none">
            <LogoButton />
          </div>

          <ErrorBoundary>
            <Header
              showSearch={false}
              location={this.props.location}
              onHeaderClassesChange={(classes) =>
                this.setState({ headerClasses: classes })
              }
            />
          </ErrorBoundary>

          <main className="main-page-content">
            <ErrorBoundary>
              <AnimatePresence
                mode="wait"
                initial={false}
                onExitComplete={() => {
                  window.scrollTo(0, 0);
                }}
              >
                <Switch
                  location={this.props.location}
                  key={this.props.location.pathname}
                >
                  {/* Internal Pages */}
                  <Route exact path="/intern/login" component={LoginForm} />
                  <Route
                    exact
                    path="/intern/forgot-password"
                    component={ForgotPasswordForm}
                  />
                  <Route
                    exact
                    path="/intern/reset-pass/temp"
                    component={ResetPasswordWithTempPasswordForm}
                  />
                  <Route
                    exact
                    path="/intern/webform/:webformId/:submissionId?"
                    component={WebformPage}
                  />
                  <Route exact path="/intern/:alias" component={Landingpage} />
                  {/* Public Pages */}
                  <Route
                    exact
                    path="/:language?/projekt/:alias"
                    component={project}
                  />
                  <Route
                    exact
                    path="/:language?/project/:alias"
                    component={project}
                  />
                  <Route
                    exact
                    path="/:language?/news/:alias"
                    component={News}
                  />
                  <Route
                    exact
                    path="/:language?/veranstaltung/:alias"
                    component={Event}
                  />
                  {/* <Route
                  exact
                  path="/:language?/person/:alias"
                  component={Person}
                  /> */}
                  <Route
                    exact
                    path="/node/:nodeId/revisions/:revisionId/view"
                    component={NodeRevision}
                  />
                  <Route
                    exact
                    path="/:language?/:alias"
                    component={Landingpage}
                  />
                  <Route exact path="/" component={Landingpage} />
                </Switch>
              </AnimatePresence>
            </ErrorBoundary>
          </main>

          <ErrorBoundary>
            <PageFooter />
          </ErrorBoundary>
        </div>
      </IntlProvider>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func.isRequired,
  reduxStore: PropTypes.object,
  location: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withRouter(App));
