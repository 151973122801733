import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";

const StringFilter = ({ item, onEnter }) => {
  return (
    <div className="filter-string">
      <Field
        id={item.options.id}
        name={item.options.id}
        type="text"
        placeholder={item.options.expose.placeholder}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            onEnter();
          }
        }}
      />
    </div>
  );
};

StringFilter.propTypes = {
  item: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.shape({
      id: PropTypes.string,
      expose: PropTypes.shape({
        label: PropTypes.string,
        placeholder: PropTypes.string,
      }),
    }),
  }),
  onEnter: PropTypes.func,
};

export default StringFilter;
