import { useEffect } from "react";

const useLockBodyScroll = () => {
  useEffect(() => {
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.documentElement.style.overflow = "visible";
    };
  });
};

export const LockBodyScroll = () => {
  useLockBodyScroll();
  return null;
};

export default useLockBodyScroll;
