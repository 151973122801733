const trunc = (text, n, useWordBoundary, threeDots = false) => {
  if (text.length <= n) {
    return text;
  }

  let subString = text.substr(0, n - 1);

  subString = useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(" "))
    : subString;

  if (threeDots) {
    return `${subString}&hellip;`;
  }

  return subString;
};

export { trunc };
