import React, { Component } from "react";
import PropTypes from "prop-types";
import { Select, SelectItem } from "carbon-components-react";

class ComponentExtendedTeaserOverviewFilterDialogBaum extends Component {
  state = {};

  render() {
    return (
      <section className="filter-dialog-baum">
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-15 offset-md-1">
              {this.props.items.map((item, index) =>
                (() => {
                  switch (item.entityBundle) {
                    case "filtertext_text":
                      return <p key={index}>{item.fieldFilterTextText}</p>;
                    case "filteroptionen":
                      return (
                        <Select
                          key={index}
                          id={item.id}
                          hideLabel={true}
                          noLabel={true}
                          inline={false}
                          defaultValue="none"
                          value={
                            this.props.activeFilter
                              ? this.props.activeFilter.id
                              : "none"
                          }
                          size={"xl"}
                          onChange={(event) =>
                            event.target.value === "none"
                              ? this.props.changeActiveFilter({
                                  id: "none",
                                })
                              : this.props.changeActiveFilter(
                                  item.fieldFilterMultiple.filter(
                                    (tag) => tag.id == event.target.value
                                  )[0]
                                )
                          }
                        >
                          <SelectItem value="none" text="Choose an option" />
                          {item.fieldFilterMultiple.map((tag, tagIndex) => (
                            <SelectItem
                              value={tag.id}
                              text={tag.label}
                              key={tagIndex}
                            />
                          ))}
                        </Select>
                      );
                    default:
                      return null;
                  }
                })()
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

ComponentExtendedTeaserOverviewFilterDialogBaum.propTypes = {
  activeFilter: PropTypes.shape({
    id: PropTypes.string,
  }),
  changeActiveFilter: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      entityBundle: PropTypes.oneOf(["filtertext_text", "filteroptionen"]),
      fieldFilterTextText: PropTypes.string,
      fieldFilterMultiple: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    })
  ),
};

export default ComponentExtendedTeaserOverviewFilterDialogBaum;
