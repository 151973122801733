import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

import LoadingIndicator from "../../general-components/loading-indicator";
import ContentBase from "../../general-components/content-base/content-base";
import eventPageQuery from "./event.graphql";
import PagerFullPage from "../../general-components/pager-full-page";
import { withRouter } from "react-router-dom";
import Error500 from "../../system/error-500";
import Error404 from "../../system/error-404";
import { useQuery } from "@apollo/client";
import { useI18n } from "../../i18n/use-i18n";

const Event = (props) => {
  const queryStrings = props.location.search;

  /**
   * Hook to get information from Redux Store
   */
  const reduxStore = useSelector((reduxStore) => ({
    pagerConfig: reduxStore.appStore.pagerFullPages,
  }));

  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  // @todo: language variations are not EntityCanonicalUrl but RedirectUrl
  const { data, loading, error } = useQuery(eventPageQuery, {
    variables: {
      path: props.location.pathname,
    },
  });

  useI18n(data?.route.languageSwitchLinks);

  // Return the loading indicator while loading
  if (loading) {
    return <LoadingIndicator />;
  }

  // Return the 500 error component if query was not successful
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but data is empty
  if (!data?.route && !loading) {
    return <Error404 />;
  }

  const published = data.route.entity.status
    ? "node-published"
    : "node-unpublished";

  return (
    <article
      className={`node-${data.route.entity.entityBundle} node-${data.route.entity.id} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`Hadi Teherani | ${data.route.entity.label}`}</title>
      </Helmet>

      <ContentBase
        content={data.route.entity.fieldModules}
        nodeContent={data.route.entity}
      />

      {queryStrings.p &&
        reduxStore.pagerConfig.filter((config) => config.id === queryStrings.p)
          .length > 0 && (
          <PagerFullPage
            config={
              reduxStore.pagerConfig.filter(
                (config) => config.id === queryStrings.p
              )[0]
            }
            currentId={data.route.entity.entityId}
          />
        )}
    </article>
  );
};

Event.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default withRouter(Event);
