import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronUp24, ChevronDown24 } from "@carbon/icons-react";

import Image from "../../../image/image";
import FieldTime from "../../../date-time/field-time";
import ErrorBoundary from "../../../../error-boundary";

const ParagraphDownloadSchublade = ({ content }) => {
  const [open, setOpen] = useState(false);

  const sectionClassNames = classNames({
    "paragraph paragraph-download-schublade": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16 col-md-15 offset-md-1">
            <div className="row">
              <div className="col-16 col-md-12">
                <header>
                  <ErrorBoundary>
                    <FieldTime
                      date={content.fieldDatum.value}
                      format={"DD.MM.YYY"}
                    />
                  </ErrorBoundary>
                  <span>{content.fieldRubrikOderKategorie}</span>
                </header>
                <h3>{content.fieldTitel}</h3>
                {content.fieldBeschreibung && (
                  <div
                    className="text"
                    dangerouslySetInnerHTML={{
                      __html: content.fieldBeschreibung,
                    }}
                  />
                )}
              </div>
              <div className="d-none col-16 col-md-4 d-md-flex justify-content-end align-items-baseline">
                <div
                  className="btn btn-secondary btn-icon"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  {open ? (
                    <>
                      Einklappen <ChevronUp24 />
                    </>
                  ) : (
                    <>
                      Ausklappen <ChevronDown24 />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className={classNames({
                "row downloads": true,
                "d-none": !open,
                "d-flex": open,
              })}
            >
              {content.fieldDownloads.map((item, index) => (
                <div className="col-8 col-md-3" key={index}>
                  <a
                    href={item.mediaFileUrl.path}
                    className="download"
                    download={true}
                  >
                    {item.fieldVorschaubild !== null ? (
                      <ErrorBoundary>
                        <Image data={item.fieldVorschaubild.fieldMediaImage} />
                      </ErrorBoundary>
                    ) : (
                      <div className="text-image">
                        {item.mediaFileUrl.path.split(".").pop()}
                      </div>
                    )}
                    <div className="download-title">{item.label}</div>
                  </a>
                </div>
              ))}
            </div>
            <div className="row">
              <div className="d-flex col-16 col-md-4 d-md-none justify-content-end align-items-baseline">
                <div
                  className="btn btn-secondary"
                  onClick={() => setOpen((prev) => !prev)}
                >
                  {open ? (
                    <>
                      Einklappen <ChevronUp24 />
                    </>
                  ) : (
                    <>
                      Ausklappen <ChevronDown24 />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphDownloadSchublade.propTypes = {
  content: PropTypes.shape({
    fieldDatum: PropTypes.shape({
      value: PropTypes.string,
    }),
    fieldRubrikOderKategorie: PropTypes.string,
    fieldTitel: PropTypes.string,
    fieldBeschreibung: PropTypes.string,
    fieldDownloads: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        fieldVorschaubild: PropTypes.shape({
          fieldMediaImage: PropTypes.shape({
            alt: PropTypes.string,
            title: PropTypes.string,
            derivative: PropTypes.shape({
              urlPath: PropTypes.string,
            }),
          }),
        }),
        mediaFileUrl: PropTypes.shape({
          path: PropTypes.string,
        }),
      })
    ),
  }),
};

export default ParagraphDownloadSchublade;
