import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";
import ImageSlider from "../../../image-slider/image-slider";

const ParagraphTeaserImage = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-teaser-image": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-12 col-lg-6": true,
              "order-lg-2": content.fieldImagePosition === "left",
            })}
          >
            {content.fieldText && (
              <div
                className="text hero"
                dangerouslySetInnerHTML={{
                  __html: content.fieldText,
                }}
              />
            )}
          </div>
          <div
            className={classNames({
              "col-12 col-lg-6": true,
              "order-lg-1": content.fieldImagePosition === "left",
            })}
          >
            <ImageSlider images={content.fieldImageRawField.list} />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphTeaserImage.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }),
      }),
    }),
    fieldCallToAction: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.string,
      }),
    }),
    fieldText: PropTypes.string,
    fieldImagePosition: PropTypes.oneOf(["left", "right"]),
  }),
};

export default ParagraphTeaserImage;
