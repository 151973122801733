import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserNews extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-news">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.id}
          destinationRoute={this.props.location.pathname}
        />
        <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={
            this.props.item.url
              ? this.props.item.url.path
              : this.props.item.path.alias
          }
        >
          <Image
            data={this.props.item.fieldTeaserbild.fieldMediaImage}
            nodeTitle={this.props.item.title}
          />
          {this.props.item.fieldSchlagwort.length > 0 &&
            this.props.item.fieldSchlagwort[0] && (
              <span className="top-line">
                {this.props.item.fieldSchlagwort[0].name}
              </span>
            )}
          <h3>{this.props.item.title}</h3>
          {this.props.item.fieldTeasertext && (
            <div
              className="teaser text"
              dangerouslySetInnerHTML={{
                __html: this.props.item.fieldTeasertext,
              }}
            />
          )}
        </Link>
      </article>
    );
  }
}

export const teaserNewsPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  publishedAtRawField: PropTypes.shape({
    first: PropTypes.shape({
      value: PropTypes.string,
      publishedAtOrNow: PropTypes.string,
    }),
  }),
  fieldSchlagwort: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  fieldTeasertext: PropTypes.string,
  fieldTeaserbild: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserNews.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserNewsPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserNews));
