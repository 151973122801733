import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useFormikContext } from "formik";

import { states } from "../states";

const MarkupField = ({ item }) => {
  const { values } = useFormikContext();

  const { invisible, visible } = states(item["#states"], values);

  return (
    <div
      className={classNames({
        markup: true,
        hidden: invisible || !visible,
      })}
      dangerouslySetInnerHTML={{
        __html: item["#markup"],
      }}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    />
  );
};

MarkupField.propTypes = {
  item: PropTypes.shape({
    "#markup": PropTypes.string,
    "#flex": PropTypes.string,
    "#states": PropTypes.object,
  }),
};

export default MarkupField;
