import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const ParagraphZitat = ({ content, subgrid = true }) => {
  const { fieldZitat, fieldQuelle } = content;

  const sectionClassNames = classNames({
    "paragraph paragraph-zitat": true,
  });

  const quote = (
    <blockquote className="text">
      <span className="quote-inner">»{fieldZitat.replace(/<[^>]*>?/gm, "")}«</span>
      <cite>{fieldQuelle}</cite>
    </blockquote>
  );

  if (subgrid) {
    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3">
              {quote}
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return <section className={sectionClassNames}>{quote}</section>;
  }
};

ParagraphZitat.propTypes = {
  subgrid: PropTypes.bool,
  content: PropTypes.shape({
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.object,
    }),
    fieldZitat: PropTypes.string.isRequired,
    fieldQuelle: PropTypes.string.isRequired,
  }),
};

ParagraphZitat.defaultProps = {
  content: {
    fieldImage: null,
  },
};

export default ParagraphZitat;
