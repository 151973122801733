const adminAppAction = (adminApp) => ({
    adminApp,
    type: "ADMIN_APP",
  }),
  pagerFullPagesAction = (config) => ({
    config,
    type: "PAGER_FULL_PAGE",
  }),
  pagerFullPageContextAction = (id) => ({
    id,
    type: "PAGER_FULL_PAGE_CONTEXT_ID",
  }),
  microSiteAction = (microSite) => ({
    microSite,
    type: "MICROSITE",
  }),
  microSiteFrontPageAction = (microSiteFrontPage) => ({
    microSiteFrontPage,
    type: "MICROSITE_FRONTPAGE",
  }),
  searchOpenAction = (searchOpen) => ({
    searchOpen,
    type: "SEARCH_OPEN",
  });

export {
  adminAppAction,
  pagerFullPagesAction,
  pagerFullPageContextAction,
  microSiteFrontPageAction,
  microSiteAction,
  searchOpenAction,
};
