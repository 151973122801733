import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import menuQuery from "../general-components/navigation/menu-query.graphql";
import { Menu } from "../general-components/navigation/menu";
import Search from "./../general-components/search/search";
import classNames from "classnames";
import LanguageSwitch from "../i18n/language-switch";
import { FormattedMessage } from "react-intl";
import { AnimatePresence, motion } from "framer-motion";
import { LockBodyScroll } from "../hooks/useLockBodyScroll";

const mapStateToProps = (reduxStore) => ({
  currentLanguage: reduxStore.i18n.currentLanguage,
});

/**
 * Close on menu link click.
 */
const SimpleNavigation = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openTree, setOpenTree] = useState(false);

  const toggleFullScreenMenu = () => {
    setMenuOpen((prev) => {
      prev
        ? document.body.classList.remove("fullscreen-menu-open")
        : document.body.classList.add("fullscreen-menu-open");

      return !prev;
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "Escape") {
      toggleFullScreenMenu();
    }
  };

  return (
    <>
      <div className={`main-navigation-wrapper d-flex`}>
        <Menu
          menuName="MAIN"
          navClassName="main-menu-desktop d-none d-lg-block"
          location={props.location}
        />

        <LanguageSwitch />
      </div>

      {props.showSearch && <Search />}

      <div
        id="toggle-fullscreen-menu"
        tabIndex={0}
        className={classNames({
          active: menuOpen,
          "d-flex d-lg-none": true,
        })}
        aria-label="toggle-full-screen-menu"
        onClick={toggleFullScreenMenu}
        onKeyDown={handleKeyDown}
      >
        <span>
          {menuOpen ? (
            <FormattedMessage id="close" />
          ) : (
            <FormattedMessage id="menu" />
          )}
        </span>
        <svg width="15" height="8" viewBox="0 0 15 8" fill="none">
          <path
            d="M0 1H14"
            stroke="white"
            strokeLinecap="square"
            className="line-1"
          />
          <path
            d="M0 7H14"
            stroke="white"
            strokeLinecap="square"
            className="line-2"
          />
        </svg>
      </div>

      <AnimatePresence mode="wait">
        {menuOpen && (
          <motion.div
            id="fullscreen-menu"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <LockBodyScroll />
            <div className="main-menu">
              <div className="blurry-backdrop" />
              <div className="container">
                <Menu
                  menuName="MAIN"
                  showLabel={false}
                  itemOnClickHandler={toggleFullScreenMenu}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const SingleLinkPropType = PropTypes.shape({
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
  url: PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
});

const LinkPropType = PropTypes.shape({
  link: SingleLinkPropType,
  subtree: PropTypes.arrayOf(
    PropTypes.shape({
      link: SingleLinkPropType,
      subtree: PropTypes.arrayOf(
        PropTypes.shape({
          link: SingleLinkPropType,
          /*TODO: Recursive? */
          subtree: PropTypes.array,
        })
      ).isRequired,
    })
  ).isRequired,
});

SimpleNavigation.propTypes = {
  showSearch: PropTypes.bool,
  mainMenuQuery: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    menuByName: PropTypes.shape({
      label: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(LinkPropType).isRequired,
    }),
  }),
};

export default connect(mapStateToProps)(
  compose(
    graphql(menuQuery, {
      options: () => ({
        variables: {
          name: "MAIN",
          /*language: props.currentLanguage
            ? props.currentLanguage.toUpperCase()
            : "DE",*/
        },
      }),
      name: "mainMenuQuery",
    })
  )(SimpleNavigation)
);
