import React from "react";
import PropTypes from "prop-types";

import SimpleNavigation from "./simple-navigation";
import LogoButton from "../general-components/logo-button";
import useScrollHeaderHelper from "../hooks/use-scroll-header-helper";

const Header = (props) => {
  useScrollHeaderHelper(props.onHeaderClassesChange);

  return (
    <header id="site-header">
      <div className="container">
        <div className="logo-wrapper d-none d-lg-block">
          <LogoButton />
        </div>
        <SimpleNavigation
          showSearch={props.showSearch}
          location={props.location}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  showSearch: PropTypes.bool,
};

export default Header;
