import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import TeaserNews, {
  teaserNewsPropTypes,
} from "../../../teaser-base/news/teaser-news";
import TeaserPerson, {
  teaserPersonPropTypes,
} from "../../../teaser-base/person/teaser-person";
import ErrorBoundary from "../../../../error-boundary";

/**
 * Maximum of three news or person entries.
 *
 * @todo Events.
 */
const ParagraphDossier = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-dossier": true,
    "with-background-image": content.fieldImage !== null,
  });

  let customStyle = {};

  if (content.fieldImage !== null) {
    customStyle = {
      background: `url(${content.fieldImage.fieldMediaImage.style.url})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
  }

  return (
    <section className={sectionClassNames}>
      {content.fieldImage !== null && (
        <div className="background-image" style={customStyle} />
      )}
      {content.fieldHeading && (
        <div className="container">
          <div className="row">
            <div className="col-16 col-md-10 offset-md-1">
              <h2>{content.fieldHeading}</h2>
            </div>
          </div>
        </div>
      )}

      <div className="container">
        <div className="row">
          <div className="col-16 col-md-14 offset-md-1">
            <div className="row items">
              {content.fieldBeitraegeDossier.map((item, index) => (
                <div
                  className={classNames({
                    "col-16 col-md-8": index === 0,
                    "col-16 col-md-4": index !== 0,
                  })}
                  key={index}
                >
                  {(() => {
                    /* TODO: Veranstaltung */
                    switch (item.entityBundle) {
                      case "news":
                        return (
                          <ErrorBoundary>
                            <TeaserNews item={item} />
                          </ErrorBoundary>
                        );
                      case "person":
                        return (
                          <ErrorBoundary>
                            <TeaserPerson item={item} />
                          </ErrorBoundary>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphDossier.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldImage: PropTypes.shape({
      fieldMediaImage: PropTypes.shape({
        alt: PropTypes.string,
        title: PropTypes.string,
        style: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
    fieldBeitraegeDossier: PropTypes.arrayOf(
      PropTypes.oneOfType([teaserNewsPropTypes, teaserPersonPropTypes])
    ),
  }),
};

export default ParagraphDossier;
