import React from "react";
import PropTypes from "prop-types";
import { hyphenateSync as hyphenate } from "hyphen/de";

const Hyphenate = ({ children }) => {
  return <>{hyphenate(children)}</>;
};

Hyphenate.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Hyphenate;
