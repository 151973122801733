import React, { Component } from "react";
import PropTypes from "prop-types";
import Image from "../../image/image";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import EditButton from "../../../backend/edit-button";
import { pagerFullPageContextAction } from "../../../app-actions";

/**
 * Redux mapStateToProps Function to get information from Redux Store.
 * @param {Object} reduxStore - Redux Store State
 * @returns {{adminApp: *}}
 *   Redux Store.
 */
const mapStateToProps = (reduxStore) => ({
  adminApp: reduxStore.appStore.adminApp,
});

class TeaserGeneral extends Component {
  static defaultProps = { pagerFullPage: false };

  render() {
    return (
      <article className="node node-teaser teaser-news">
        <EditButton
          adminApp={this.props.adminApp}
          entityId={this.props.item.id}
          destinationRoute={this.props.location.pathname}
        />
        <Link
          onClick={() =>
            this.props.dispatch(
              pagerFullPageContextAction(this.props.pagerFullPage)
            )
          }
          className="flex-wrap"
          to={
            this.props.item.url
              ? this.props.item.url.path
              : this.props.item.path.alias
          }
        >
          {this.props.item.fieldTeaserbild && (
            <Image
              data={this.props.item.fieldTeaserbild.fieldMediaImage}
              nodeTitle={this.props.item.title}
            />
          )}
          <div className="text-wrapper">
            <h3>{this.props.item.title}</h3>
          </div>
        </Link>
      </article>
    );
  }
}

export const teaserGeneralPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.shape({
    alias: PropTypes.string,
  }),
  url: PropTypes.shape({
    path: PropTypes.string,
  }),
  fieldTeaserbild: PropTypes.shape({
    fieldMediaImage: PropTypes.shape({
      alt: PropTypes.string,
      title: PropTypes.string,
      style: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
});

TeaserGeneral.propTypes = {
  adminApp: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  item: teaserGeneralPropTypes,
  location: PropTypes.object.isRequired,
  pagerFullPage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    .isRequired,
};

export default connect(mapStateToProps)(withRouter(TeaserGeneral));
