import { useEffect, useState } from "react";
import classNames from "classnames";

/**
 * Custom hook that adds scroll-related functionality to the header component.
 * @returns {string} The class names to be applied to the header component.
 */
const useScrollHeaderHelper = (callback = () => {}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isTop, setIsTop] = useState(true);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const headerClassNames = classNames({
    "scrolling-down": isScrollingDown,
    "is-top": isTop,
    scrolled: isScrolled,
  });

  useEffect(() => {
    setScrollPosition(window.scrollY);

    // Event handler for the scroll event.
    const scrollHandler = (e) => {
      setScrollPosition((prev) => {
        prev <= window.scrollY
          ? setIsScrollingDown(true)
          : setIsScrollingDown(false);
        return window.scrollY;
      });
    };

    document.addEventListener("scroll", scrollHandler);

    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  useEffect(() => {
    scrollPosition >= 250 ? setIsTop(false) : setIsTop(true);
    scrollPosition > 0 ? setIsScrolled(true) : setIsScrolled(false);
  }, [scrollPosition]);

  useEffect(() => {
    callback(headerClassNames);
  }, [isTop, isScrollingDown, isScrolled])

  return headerClassNames;
};

export default useScrollHeaderHelper;
