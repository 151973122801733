import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Image from "../../../image/image";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { restHostBackend } from "../../../../config";

const ParagraphIntro = ({ content }) => {
  const isPortraitMode = useMediaQuery({ query: "(max-aspect-ratio: 1/1)" });
  const imageField = isPortraitMode
    ? "fieldImagePortrait"
    : "fieldImageLandscape";

  const scrollHandler = useCallback(() => {
    const viewportBottomPosition = window.scrollY + window.outerHeight;
    const introHeight = document
      .getElementsByClassName("paragraph-intro")[0]
      .getBoundingClientRect().height;

    if (viewportBottomPosition < introHeight && !document.body.classList.contains("intro-in-viewport")) {
      document.body.classList.add("intro-in-viewport");
    } else if (viewportBottomPosition >= introHeight && document.body.classList.contains("intro-in-viewport")) {
      document.body.classList.remove("intro-in-viewport");
    }
  }, []);

  useEffect(() => {
    scrollHandler();
    document.addEventListener("scroll", scrollHandler);

    return () => document.removeEventListener("scroll", scrollHandler);
  }, []);

  return (
    <section className="paragraph paragraph-intro">
      {content.fieldIntroSlides.map((slide) => (
        <div
          key={slide.id}
          className="intro-slide"
        >
          {slide[imageField]?.__typename === "MediaVideo" && (
            <video
              src={restHostBackend + slide[imageField].mediaFileUrl?.path}
              autoPlay
              controls={false}
              loop
              muted
            />
          )}
          {slide[imageField]?.__typename === "MediaBild" && (
            <Image data={slide[imageField].fieldMediaImage} />
          )}
          <div className="chip-wrapper">
            <div>
              <Link
                className="chip"
                to={slide.fieldReferencedProjects.url?.path}
              >
                {slide.fieldReferencedProjects.title}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};

ParagraphIntro.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
  }),
};

export default ParagraphIntro;
