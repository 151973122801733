import { useFormikContext } from "formik";
import { useEffect } from "react";

const SubmitOnClose = () => {
  const { isValid, submitForm } = useFormikContext();

  useEffect(() => {
    return () => {
      if (isValid) {
        void submitForm();
      }
    };
  }, []);

  return null;
};

SubmitOnClose.propTypes = {};

export default SubmitOnClose;
