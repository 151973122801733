import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/client";
import QueryExtendedTeaserView from "../query-overview.graphql";

// Context for OverviewProvider.

export const OverviewContext = React.createContext({});

// Provider for OverviewContext.
const OverviewProvider = ({ children, content }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [currentFilters, setCurrentFilters] = useState({
    combine: "",
    field_design_filter_target_id: [],
    field_discipline_target_id: [],
    field_interior_filter_target_id: [],
    field_kategorie_target_id: [],
    field_region_target_id: [],
  });
  const [pageMode, setPageMode] = useState("pagination");
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({
    totalRows: 1,
    perPage: 30,
  });

  const [discipline, setDiscipline] = useState([]);

  // Mapping is used because this project is still using old content type naming but new overview component.
  const contentTypeMapping = {
    news: "news",
    veranstaltung: "event",
    projekt: "project",
    person: "person",
  };

  // The view ID is used to fetch the correct view.
  const viewId = `overview_${contentTypeMapping[content.fieldContentType]}`;

  useEffect(() => {
    setLoading(true);
  }, [currentFilters, currentPage]);

  // Fetch nodes based on current filters.
  // @see https://graphql-core-schema.netlify.app/schema-extensions/views.html
  const { error } = useQuery(QueryExtendedTeaserView, {
    variables: {
      viewId,
      type: [content.fieldContentType],
      page: currentPage,
      title: currentFilters.title,
      fieldDisciplineTargetId: currentFilters.field_discipline_target_id,
      fieldKategorieTargetId: currentFilters.field_kategorie_target_id,
      fieldRegionTargetId: discipline.includes("architecture")
        ? currentFilters.field_region_target_id
        : [],
      fieldDesignTargetId: discipline.includes("design")
        ? currentFilters.field_design_filter_target_id
        : [],
      fieldInteriorTargetId: discipline.includes("interior")
        ? currentFilters.field_interior_filter_target_id
        : [],
      combine: currentFilters.combine,
    },
    nextFetchPolicy: "standby",
    // Update the nodes and metadata when the query is completed.
    onCompleted: (data) => {
      setNodes((prevNodes) => {
        // Components <EndlessScroll /> and <Pagination /> set the page mode.
        if (pageMode === "pagination")
          return data.entityById.executable.execute.rows;
        if (pageMode === "endless-scroll")
          return [
            ...(prevNodes || []),
            ...data.entityById.executable.execute.rows,
          ];
      });
      setMetaData({
        totalRows: data.entityById.executable.execute.total_rows,
        perPage: data.entityById.executable.pager.perPage,
      });
      setLoading(false);
    },
    onError: () => {
      setLoading(false);
    },
  });

  const resetNodes = () => {
    setCurrentPage(0);
    setNodes([]);
    setMetaData({
      totalRows: 1,
      perPage: 30,
    });
  };

  // Exposed functions and values for the OverviewContext.
  const value = {
    viewId,
    contentType: content.fieldContentType,
    metaData,
    currentPage,
    setCurrentPage,
    currentFilters,
    setCurrentFilters,
    discipline,
    setDiscipline,
    setPageMode,
    nodes,
    resetNodes,
    loading,
    error,
  };

  return (
    <OverviewContext.Provider value={value}>
      {children}
    </OverviewContext.Provider>
  );
};

OverviewProvider.propTypes = {
  children: PropTypes.node,
  content: PropTypes.shape({
    fieldContentType: PropTypes.string,
  }),
};

export default OverviewProvider;
