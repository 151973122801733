import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import classNames from "classnames";

import { states } from "../states";

const RadioButtonField = ({ item }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item["#states"],
    values
  );

  return (
    <div
      className={classNames({
        "form-group": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={item.id}>
        {item["#title"]}
        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>
      {Object.keys(item["#options"]).map((key) => {
        return (
          <div key={item["#options"][key]} className="form-check">
            <Field
              className="form-check-input"
              type="radio"
              name={item.id}
              id={item["#options"][key]}
              onChange={handleChange}
              onBlur={handleBlur}
              value={item["#options"][key]}
              required={
                (!!item["#required"] || required) && !optional && visible
              }
              disabled={!enabled || disabled}
            />
            <label className="form-check-label" htmlFor={item["#options"][key]}>
              {key}
            </label>
          </div>
        );
      })}
      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />
      {!!item["#description"] && (
        <small className="form-description text-muted form-text">
          {item["#description"]}
        </small>
      )}
    </div>
  );
};

RadioButtonField.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    "#title": PropTypes.string,
    "#states": PropTypes.array,
    "#description": PropTypes.string,
    "#flex": PropTypes.number,
    "#required": PropTypes.object,
    "#options": PropTypes.objectOf(PropTypes.string),
  }),
};

export default RadioButtonField;
