import React from "react";
import PropTypes from "prop-types";
import ContentBase from "../../content-base";
import classNames from "classnames";

const ParagraphSection = ({ content }) => {
  return (
    <section
      className={classNames({
        "paragraph paragraph-section": true,
        "bg-grey": content.fieldColoredBackground,
      })}
      id={content.fieldHeadline.replace(/\s+/g, "-").toLowerCase()}
    >
      <div className="container">
        <div className="row">
          <div
            className={classNames({
              "col-12 col-lg-6": true,
              "order-lg-2": content.fieldOrder === "modules_headline",
            })}
          >
            <h2 className="text-center headline-l">{content.fieldHeadline}</h2>
          </div>
          <div
            className={classNames({
              "col-12 col-lg-6": true,
              "order-lg-1": content.fieldOrder === "modules_headline",
              "content-area": true
            })}
          >
            <ContentBase
              content={content.fieldModules}
              paragraphSubgrid={false}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphSection.propTypes = {
  content: PropTypes.shape({
    fieldHeadline: PropTypes.string.isRequired,
    fieldModules: PropTypes.object,
    fieldOrder: PropTypes.oneOf(["modules_headline", "headline_modules"]),
    fieldColoredBackground: PropTypes.bool,
  }),
};

export default ParagraphSection;
