import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";

import teaserNodeQueryFilterTag from "../../../teaser-base/queries/teaser-node-query-tag-filtered.graphql";
import LoadingIndicator from "../../../loading-indicator";
import TeaserBase from "../../../teaser-base/teaser-base";
import { useLazyQuery } from "@apollo/client";

const ParagraphTimeline = ({ content }) => {
  const [nodes, setNodes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const offsetSteps = content.fieldAnzahlDerAnzuzeigendenI;

  const reduxStore = useSelector((reduxStore) => ({
    timelineConfig: reduxStore.timeLine.config,
    currentLanguage: reduxStore.i18n.currentLanguage,
  }));


  const [getNodes] = useLazyQuery(
    teaserNodeQueryFilterTag,
    (() => {
      const tagConfig = reduxStore.timelineConfig.filter(
        (item) => item.timeLineId === content.id
      );
      const tagFiltered = tagConfig.length === 1 && tagConfig[0].config.id;

      let tags = [];

      // TODO: Not tag multi select, just one tag at a time.
      if (tagFiltered) {
        tags = [tagConfig[0].config.id];
      }

      if (content.fieldSchlagwort.length > 0) {
        for (const tag of content.fieldSchlagwort) {
          tags = [...tags, parseInt(tag.id, 10)];
        }
      }

      return {
        variables: {
          limit: content.fieldAnzahlDerAnzuzeigendenI,
          type: content.fieldTypenTimeline,
          status: ["1"],
          tag: tags,
          filterTagEnabled: tags.length > 0,
          language: reduxStore.currentLanguage.toUpperCase(),
        },
      };
    })()
  );


  useEffect(() => {
    getNodes({ variables: { offset } })
      .then(({ data }) => {
        setNodes((prevNodes) => [...prevNodes, ...data.entityQuery.items]);
        setTotalCount(data.entityQuery.total);
      })
      .finally(() => setLoading(false));
  }, [offset]);

  const loadMoreNodes = () => {
    setLoading(true);
    setOffset((prev) => prev + offsetSteps);
  };

  const sectionClassNames = classNames({
    "paragraph paragraph-timeline": true,
  });

  const loadMoreEnabled =
    nodes.length < totalCount && content.fieldZeigeMehrLaden;

  if (!nodes.length) {
    return <LoadingIndicator />;
  }

  return (
    <section className={sectionClassNames}>
      <div className="container">
        {nodes.map((item, index) => (
          <TeaserBase item={item} key={index} viewMode={"timeline"} />
        ))}

        {loadMoreEnabled && (
          <div className="d-flex justify-content-center load-more-wrapper">
            <div className="btn btn-primary" onClick={loadMoreNodes}>
              <span>
                {!loading && <FormattedMessage id="load_more" />}
                {loading && <><FormattedMessage id="loading" />...</>}
              </span>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

ParagraphTimeline.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    fieldFilterwolke: PropTypes.bool,
    fieldListenumschalter: PropTypes.bool,
    fieldZeigeMehrLaden: PropTypes.bool,
    fieldTypenTimeline: PropTypes.arrayOf(
      PropTypes.oneOf(["news", "blogbeitrag"])
    ),
    fieldAnzahlDerAnzuzeigendenI: PropTypes.number,
    fieldSchlagwort: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      })
    ),
  }),
};

export default ParagraphTimeline;
