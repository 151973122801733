import React from "react";
import PropTypes from "prop-types";

import LoadingIndicator from "../general-components/loading-indicator";
import { SocialMedia } from "./components/social-media";
import { Menu } from "../general-components/navigation/menu";
import useConfigQuery from "../hooks/use-config-query";

const PageFooter = (props) => {
  const {
    data: footerConfig,
    loading,
    error,
  } = useConfigQuery("footer_social_media");

  if (footerConfig) {
    return (
      <footer id="pageFooter">
        <div className="container">
          {footerConfig && (
            <div className="row">
              <div className="col-6 col-lg-2">
                {footerConfig.fieldFooterSitemapZeigen && (
                  <Menu
                    menuName={
                      footerConfig.fieldMenueRawField?.list[0].targetId.toUpperCase() ||
                      "MAIN"
                    }
                  />
                )}
              </div>
              <div className="col-6 col-lg-2">
                <Menu menuName="FOOTER" />
              </div>
              <div className="col-6 offset-6 offset-lg-0 col-lg-2">
                <SocialMedia footerConfig={footerConfig} />
              </div>
              <p className="copyright">{footerConfig.fieldCopyright}</p>
            </div>
          )}
        </div>
      </footer>
    );
  }

  return <LoadingIndicator />;
};

PageFooter.propTypes = {
  adminApp: PropTypes.bool,
  footerConfig: PropTypes.shape({
    entityQuery: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          fieldFooterSitemapZeigen: PropTypes.bool,
          fieldCopyright: PropTypes.string.isRequired,
          fieldMenue: PropTypes.shape({
            targetId: PropTypes.string,
          }),
          fieldFacebook: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldYoutube: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldFlickr: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldInstagram: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldPinterest: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldLinkedin: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldTwitter: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldVimeo: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldXing: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
          fieldTumblr: PropTypes.shape({
            uri: PropTypes.shape({
              path: PropTypes.string,
            }),
          }),
        })
      ),
    }),
  }),
};

export default PageFooter;
