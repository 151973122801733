import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage, useFormikContext } from "formik";
import { states } from "../states";
import classNames from "classnames";

const CheckboxField = ({ item }) => {
  const { values, handleChange, handleBlur } = useFormikContext();

  const { invisible, visible, enabled, disabled, optional, required } = states(
    item["#states"],
    values
  );

  return (
    <div
      className={classNames({
        "form-group form-check": true,
        hidden: invisible || !visible,
      })}
      style={item["#flex"] ? { flex: item["#flex"] } : {}}
    >
      <label htmlFor={item.id}>
        <Field
          className="form-check-input"
          type="checkbox"
          name={item.id}
          id={item.id}
          onChange={handleChange}
          onBlur={handleBlur}
          required={(!!item["#required"] || required) && !optional && visible}
          disabled={!enabled || disabled}
        />

        <span>{item["#title"]}</span>

        {(!!item["#required"] || required) && !optional && visible && (
          <span className="required">*</span>
        )}
      </label>

      <ErrorMessage
        role="region"
        aria-live="polite"
        component="span"
        name={item.id}
        className="error-message"
      />

      {!!item["#description"] && (
        <small
          className="form-description text-muted form-text"
          dangerouslySetInnerHTML={{ __html: item["#description"] }}
        />
      )}
    </div>
  );
};

CheckboxField.propTypes = {
  item: PropTypes.shape({
    "#default_value": PropTypes.string,
    "#description": PropTypes.string,
    "#flex": PropTypes.number,
    "#maxlength": PropTypes.number,
    "#minlength": PropTypes.number,
    "#placeholder": PropTypes.string,
    "#required": PropTypes.bool,
    "#required_error": PropTypes.string,
    "#states": PropTypes.object,
    "#submit__label": PropTypes.string,
    "#title": PropTypes.string,
    "#type": PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

export default CheckboxField;
