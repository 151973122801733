import React from "react";

const Error404 = () => {
  return (
    <div className="container error-404">
      <div className="row">
        <div className="col-12">
            <h1 className="text-center">404</h1>
            <p className="text-center">Die angeforderte Seite konnte nicht gefunden werden.</p>
        </div>
      </div>
    </div>
  );
};

export default Error404;
