import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Slider from "react-slick";

import ErrorBoundary from "../../../../../error-boundary";
import Link from "../../../../link/link";
import { sliderSettings } from "../components/component-teaserlist-carousel";
import TeaserGeneral, {
  teaserGeneralPropTypes,
} from "../../../../teaser-base/general/teaser-general";

/**
 * Automatically shows either a Wrap or Carousel component, threshold is 4
 * items.
 */
class ParagraphTeaserListPages extends Component {
  render() {
    const sectionClassNames = classNames({
      "paragraph paragraph-teaser-list paragraph-teaser-pages": true,
      "paragraph paragraph-teaser-list-slick":
        this.props.content.fieldBeitraege.length >= 4,
    });

    return (
      <section className={sectionClassNames}>
        {this.props.content.fieldHeading && (
          <div className="container">
            <div className="row">
              <div className="col-16">
                <h2>{this.props.content.fieldHeading}</h2>
              </div>
            </div>
          </div>
        )}

        {this.props.content.fieldBeitraege.length >= 4 ? (
          <Slider {...sliderSettings}>
            {this.props.content.fieldBeitraege.map((item, index) => (
              <TeaserGeneral item={item} key={index} />
            ))}
          </Slider>
        ) : (
          <div className="container">
            <div className="row">
              {this.props.content.fieldBeitraege.map((item, index) => (
                <div
                  key={index}
                  className={classNames({
                    [`col-${
                      32 / this.props.content.fieldBeitraege.length
                    } col-lg-${16 / this.props.content.fieldBeitraege.length}`]:
                      this.props.content.fieldBeitraege.length !== 3,
                    "col-16 col-lg-5":
                      this.props.content.fieldBeitraege.length === 3 &&
                      index !== 0,
                    "col-16 col-lg-5 offset-lg-1":
                      this.props.content.fieldBeitraege.length === 3 &&
                      index === 0,
                  })}
                >
                  <TeaserGeneral item={item} />
                </div>
              ))}
            </div>
          </div>
        )}

        {this.props.content.fieldMehrMeldungenButtonZeig &&
          this.props.content.fieldMehrMeldungenButton.title && (
            <div className="container">
              <div className="row">
                <div className="col-16 d-md-flex justify-content-md-end">
                  <ErrorBoundary>
                    <Link
                      link={this.props.content.fieldMehrMeldungenButton}
                      className="btn btn-primary more"
                    />
                  </ErrorBoundary>
                </div>
              </div>
            </div>
          )}
      </section>
    );
  }
}

ParagraphTeaserListPages.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string,
    fieldMehrMeldungenButtonZeig: PropTypes.bool,
    fieldMehrMeldungenButton: PropTypes.shape({
      title: PropTypes.string,
      uri: PropTypes.shape({
        path: PropTypes.string,
        __typename: PropTypes.bool,
      }),
    }),
    fieldBeitraege: PropTypes.arrayOf(teaserGeneralPropTypes),
  }),
};

export default ParagraphTeaserListPages;
