import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 * A module representing a text paragraph.
 */
const ParagraphText = ({ content, subgrid }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-text": true,
    "background-color": content.fieldFarbigHinterlegen,
  });

  const paragraph = (
    <div
      className="text"
      dangerouslySetInnerHTML={{
        __html: content.fieldText,
      }}
    />
  );

  if (subgrid) {
    return (
      <section className={sectionClassNames}>
        <div className="container">
          <div className="row">
            <div className="col-wrapper col-12 col-lg-6">{paragraph}</div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className={sectionClassNames}>
        <div className="row">
          <div className="col-12 col-md-8 col-lg-10 col-xl-8">{paragraph}</div>
        </div>
      </section>
    );
  }
};

ParagraphText.propTypes = {
  subgrid: PropTypes.bool,
  /**
   * The modules content.
   */
  content: PropTypes.shape({
    fieldFarbigHinterlegen: PropTypes.bool,
    fieldText: PropTypes.string.isRequired,
  }).isRequired,
};

export default ParagraphText;
