import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import LoadingIndicator from "../../general-components/loading-indicator";
import newsPageQuery from "./news.graphql";
import { useI18n } from "../../i18n/use-i18n";
import { useQuery } from "@apollo/client";
import Error404 from "../../system/error-404";
import Error500 from "../../system/error-500";
import { useCanonicalOrRedirect } from "../../hooks/use-canonical-or-redirect";
import ImageSlider from "../../general-components/image-slider/image-slider";
import FieldTime from "../../general-components/date-time/field-time";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

const News = (props) => {
  const [expanded, setExpanded] = useState(false);
  /**
   * Hook to get information from Redux Store
   */
  const microSite = useSelector((state) => state.appStore.microSite);
  const microSiteFrontPage = useSelector(
    (state) => state.appStore.microSiteFrontPage
  );

  /**
   * Hook to perform GraphQL query (with help of redux store)
   */
  // @todo: language variations are not EntityCanonicalUrl but RedirectUrl
  const { data, loading, error } = useQuery(newsPageQuery, {
    variables: {
      path:
        props.location.pathname === "/" &&
        microSite &&
        microSiteFrontPage !== ""
          ? microSiteFrontPage
          : props.location.pathname,
    },
  });

  const route = useCanonicalOrRedirect(data);
  const entity = route?.entity;

  useI18n(route?.languageSwitchLinks);

  // Return the loading indicator while loading
  if (loading) {
    return <LoadingIndicator className="page-loading-indicator" />;
  }

  // Return the 500 error component if query was not successful
  if (error) {
    return <Error500 error={error} />;
  }

  // Return the 404 error component if query was successful but data is empty
  if (!entity && !loading) {
    return <Error404 />;
  }

  const published = entity.status ? "node-published" : "node-unpublished";

  return (
    <article
      className={`node-${entity.entityBundle} node-${entity.id} node-full-page ${published}`}
    >
      <Helmet>
        <title>{`Hadi Teherani | ${entity.label}`}</title>
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <ImageSlider images={entity.fieldBildWCaption} />
          </div>
          <div className="col-12 col-lg-6">
            <h3 className="headline-m">{entity.title}</h3>
            {entity.fieldSubtitle && (
              <h4 className="headline-m headline-secondary">
                {entity.fieldSubtitle}
              </h4>
            )}
            <p className="headline-m headline-secondary">
              <FieldTime
                timestamp={true}
                date={
                  entity.publishedAtRawField?.first.value ||
                  entity.publishedAtRawField?.first.publishedAtOrNow
                }
                format={"DD. MMMM YYYY"}
              />
            </p>
            <div
              className={classNames({
                "news-body": true,
                expanded: true,
              })}
            >
              {entity.fieldText && (
                <div
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: entity.fieldText,
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

News.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
  pagerConfig: PropTypes.array,
  pagerFullPageContextId: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default withRouter(News);
