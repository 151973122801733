import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Image from "../../../image/image";
import ErrorBoundary from "../../../../error-boundary";
import { LinkPropType } from "../../../link/link";

const ParagraphLogosPartners = ({ content }) => {
  const sectionClassNames = classNames({
    "paragraph paragraph-logos-partners": true,
  });

  return (
    <section className={sectionClassNames}>
      <div className="container">
        <div className="row">
          <div className="col-16">
            <div className="heading">{content.fieldHeading}</div>
          </div>
        </div>
      </div>

      <div className="container logos">
        <div className="row">
          <div
            className={classNames({
              "col-16": true,
              "col-md-12 offset-md-2":
                content.fieldLogosPartners.length !== 3,
            })}
          >
            <div
              className={classNames({
                "row align-items-center": true,
                "justify-content-center":
                  content.fieldLogosPartners.length === 1,
                "justify-content-between":
                  content.fieldLogosPartners.length > 1,
              })}
            >
              {content.fieldLogosPartners.map((item, index) => (
                <div
                  key={index}
                  className={classNames({
                    partner: true,
                    "col-8 col-md-6":
                      content.fieldLogosPartners.length !== 3,
                    "col-5": content.fieldLogosPartners.length === 3,
                  })}
                >
                  {item.fieldLink !== null ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={item.fieldLink.uri.path}
                    >
                      <Image data={item.fieldMediaImage1} />
                    </a>
                  ) : (
                    <ErrorBoundary>
                      <Image data={item.fieldMediaImage1} />
                    </ErrorBoundary>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ParagraphLogosPartners.propTypes = {
  content: PropTypes.shape({
    fieldHeading: PropTypes.string.isRequired,
    fieldLogosPartners: PropTypes.arrayOf(
      PropTypes.shape({
        fieldLink: LinkPropType,
        fieldMediaImage1: PropTypes.shape({
          style: PropTypes.shape({
            url: PropTypes.string,
          }).isRequired,
          styleFullPage: null,
          desktop: null,
          mobile: null,
        }),
      })
    ),
  }),
};

export default ParagraphLogosPartners;
